// constants for different list names. Name is used in local storage data object.
export default {
  documentDetailDialog: "documentDetailDialog",

  documentList: "documentList",
  documentDetailList: "documentDetailList",
  employeeDocumentList: "employeeDocumentList",
  leaveRecordList: "leaveRecordList",
  projectResourceList: "projectResourceList",
  assetsList: "assetsList",
  paySlipList: "paySlipList",
  teamList: "teamList",
};
