import { FormControl, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FiExternalLink } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { dateEventFormatter, dayShortName, formatDate, toMonthName } from "../../util/Helper";
import { updateDashboardFlagAction, getAllBirthDaysAction, getHolidayAction, getLinksAction, getEventsAction } from "../../redux/actions";
import IntlMessages from "../../util/IntlMessages";
import TitleHeader from "../shared/pageTitle/TitleHeader";
const HomePage = () => {
  const dispatch = useDispatch();
  const [holidays, setHolidays] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const [links, setLinks] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [companyEvent, setCompanyEvent] = useState([]);
  const [birthdayMonths, setBirthdaysMonths] = useState();

  const updateDashboardFlag = useSelector((store) => {
    return store?.utilityControllerReducer?.updateFlag;
  });
  // Get Birthday's
  const getAllBirthdays = useSelector((store) => {
    return store?.utilityControllerReducer?.utilAllBirthDays;
  });
  useEffect(() => {
    if (!getAllBirthdays) {
      dispatch(getAllBirthDaysAction());
    } else {
      setBirthdays(getAllBirthdays.employeeBirthdayList);
      setBirthdaysMonths(getAllBirthdays);
    }
  }, [getAllBirthdays]);

  const getHolidays = useSelector((store) => {
    return store?.utilityControllerReducer?.utilHolidays;
  });

  useEffect(() => {
    if (!getHolidays || updateDashboardFlag) {
      dispatch(getHolidayAction());
      dispatch(updateDashboardFlagAction());
    } else {
      setHolidays(getHolidays);
    }
  }, [getHolidays]);

  // Links
  const getLinks = useSelector((store) => {
    return store?.utilityControllerReducer?.utilLinks;
  });
  useEffect(() => {
    if (!getLinks) {
      dispatch(getLinksAction());
    } else {
      setLinks(getLinks);
    }
  }, [getLinks]);

  //Events
  const getEvents = useSelector((store) => {
    return store?.utilityControllerReducer?.utilEvents;
  });
  useEffect(() => {
    if (!getEvents) {
      dispatch(getEventsAction());
    } else {
      setCompanyEvent(getEvents);
    }
  }, [getEvents]);

  const getClickableLink = (link) => {
    return link.startsWith("http://") || link.startsWith("https://") ? link : `http://${link}`;
  };
  const uniqYear = [];
  if (uniqYear.length == 0) {
    uniqYear.push(year);
  }
  Object.keys(holidays).map((item) => {
    if (
      !uniqYear.find((items) => {
        return items === new Date(holidays[item].date).getFullYear();
      })
    ) {
      uniqYear.push(new Date(holidays[item].date).getFullYear());
    }
    return null;
  });
  return (
    <>
      <TitleHeader
        shouldShowArrow={false}
        title="HRMS-employee"
        pageTitle={"title.dashboard"}
      />
      <div className="row mb-5 columnContainer">
        <div className="col-md-4 mb-4 equalHeightColumn">
          <div className="equalHeightInner">
            <Row>
              <Col
                xs={6}
                sm={6}
                className="text-start">
                <h6 className="CardTittle">
                  <IntlMessages id="dashboard.card.title.holidays" />
                </h6>
              </Col>
              <Col
                xs={6}
                sm={6}
                className="text-end d-flex justify-content-center">
                <FormControl
                  className="yearBox mt-0"
                  style={{ width: "auto" }}>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    className="Box"
                    style={{ cursor: "pointer", height: "40px" }}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}>
                    {uniqYear.map((obj) => (
                      <option
                        key={""}
                        value={obj}
                        style={{ marginLeft: "10px" }}>
                        {obj}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <div className="cardTableDiv">
              <table className="cardInnerTable mt-2">
                <tbody>
                  {Object.keys(holidays)
                    .filter((item) => new Date(holidays[item].date).getFullYear() === year)
                    .map((holiday) => {
                      return (
                        <tr key="">
                          {/* <td className="align-middle">{dayShortName(holidays[holiday].date)}</td> */}
                          <td className="align-middle">{holidays[holiday].event}</td>
                          <td className="align-middle">{dateEventFormatter(holidays[holiday].date)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-4 equalHeightColumn">
          <div className="equalHeightInner">
            <Row>
              <Col>
                <h6 className="CardTittle">
                  <span>
                    <IntlMessages id="dashboard.card.title.birthdays" />
                  </span>
                </h6>
              </Col>
              <Col>
                <span className="show-month">
                  {toMonthName(birthdayMonths?.fromMonth)} - {toMonthName(birthdayMonths?.toMonth)}
                </span>
              </Col>
            </Row>
            <div className="cardTableDiv">
              <table className="cardInnerTable">
                <tbody>
                  {birthdays &&
                    Object.keys(birthdays).map((key) => {
                      //  const birthdayMonth = new Date(birthdays[key].dob).getMonth()
                      if (birthdays) {
                        return (
                          <tr key={key}>
                            <td>{dayShortName(birthdays[key].dob)}</td>
                            <td>{formatDate(birthdays[key].dob)}</td>
                            <td>{birthdays[key].empCode}</td>
                            <td>
                              {birthdays[key].firstName} {birthdays[key].lastName}
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-4 equalHeightColumn">
          <div className="equalHeightInner">
            <h6 className="CardTittle">
              <IntlMessages id="dashboard.card.title.importantLinks" />
            </h6>
            <div className="cardTableDiv">
              <table className="cardInnerTable">
                <tbody>
                  {Object.keys(links).map((link) => {
                    return (
                      <>
                        <div>
                          <a
                            className="impLinks"
                            href={getClickableLink(links[link].linkUrl)}
                            target="_blank">
                            <FiExternalLink />
                            &nbsp;&nbsp; {links[link].linkText}
                          </a>
                        </div>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-4 equalHeightColumn">
          <div className="equalHeightInner">
            <h6 className="CardTittle">
              <IntlMessages id="dashboard.card.title.companyEvents" />
            </h6>
            <div className="cardTableDiv">
              <table className="cardInnerTable">
                <tbody>
                  {Object.keys(companyEvent).map((key) => (
                    <tr key="">
                      <td>{companyEvent[key].eventName}</td>
                      <td>{dateEventFormatter(companyEvent[key].date)}</td>
                      <td>{companyEvent[key].time}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
