import { CubeContext } from "@cubejs-client/react";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { AxiosInstance } from "../config/AxiosConfig";
import { workDaySettingApi } from "../redux/ApiLists";
import IntlMessages from "./IntlMessages";

export default function useCalculateDaysOff() {
  const { cubejsApi } = useContext(CubeContext);
  const holidaySchema = "Holiday";
  let weekDay = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  const HOLIDAY_LIST_QUERY = {
    dimensions: ["Holiday.event", "Holiday.date", "Holiday.comment", "Holiday.holidayId"],
    order: {
      "Holiday.event": "asc",
    },
    timeDimensions: [
      {
        dimension: "Holiday.date",
        granularity: "year",
        dateRange: "This year",
      },
    ],
  };

  const getHolidayOfCurrentYear = async () => {
    return cubejsApi
      .load(HOLIDAY_LIST_QUERY)
      .then((resultSet) => {
        const response = resultSet.tablePivot();
        if (response.length > 0) {
          return response;
        } else {
          return null;
          // setLeaveBalanceData([]);
        }
      })
      .catch((error) => {
        console.error("----> Errorrr ", error);
      });
  };

  const getWorkDaySetting = async () => {
    return AxiosInstance.get(`${workDaySettingApi}`).then((response) => {
      return response;
    });
  };

  async function calculate(fDate, tDate) {
    let holidays = await getHolidayOfCurrentYear();
    let workDays = await getWorkDaySetting();
    if (Object.keys(workDays).length > 0) {
      let holidayAndWeekEndCount = 0;
      let isSameDayHolidayAndWeekend = 0;
      const millisInDay = 1000 * 60 * 60 * 24;
      let fromDate = new Date(fDate);
      fromDate.setHours(0, 0, 0, 0);
      let toDate = new Date(tDate);
      toDate.setHours(0, 0, 0, 0);

      const diffTime = Math.abs(fromDate - toDate);
      const totalAppliedLeaveDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

      for (let i = 0; i < totalAppliedLeaveDays; i++) {
        let date = new Date(fromDate.getTime() + millisInDay * i);
        if(holidays != null){
          holidays.map((e) => {
            const holiday = new Date(e[`${holidaySchema}.date`]);
            if (holiday.valueOf() == date.valueOf()) {
              holidayAndWeekEndCount++;
              if (!workDays[weekDay[date.getDay()]]) {
                isSameDayHolidayAndWeekend++;
              }
            }
          });
        }
      }

      for (let i = 0; i < totalAppliedLeaveDays; i++) {
        let date = new Date(fromDate.getTime() + millisInDay * i);
        let day = weekDay[date.getDay()];
        if (!workDays[day]) {
          holidayAndWeekEndCount++;
        }
      }

      let daysOff = totalAppliedLeaveDays - (holidayAndWeekEndCount - isSameDayHolidayAndWeekend);
      return daysOff;
    } else toast.error(<IntlMessages id="leave.record.error.message" />);
  }
  return calculate;
}
