import CloseIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Row } from "reactstrap";
import { AxiosInstanceForBlob } from "../../../config/AxiosConfig";
import IntlMessages from "../../../util/IntlMessages";
import ModelSelfFooter from "../../shared/Model/ModelSelfFooter";
import RctSectionLoader from "../../shared/spinner/RctSectionLoader";
import "./paySlipPdf.css";

const PaySlipPdf = ({ payrollDetailId, showViewOption }) => {
  PaySlipPdf.propTypes = {
    payrollDetailId: PropTypes.number.isRequired,
    showViewOption: PropTypes.bool.isRequired,
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [pdfUrl, setPDFUrl] = useState();

  const generatePDF = async () => {
    setIsLoading(true);
    await AxiosInstanceForBlob.get(`/download-payslip/${payrollDetailId}`)
      .then((response) => {
        const fileType = response.headers["content-disposition"];
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
        const fileURL = URL.createObjectURL(file);
        setIsLoading(false);
        if (!isMobile) {
          setPDFUrl(fileURL);
        } else {
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = fileURL;
          a.download = payrollDetailId + "." + fileType;
          a.click();
          window.URL.revokeObjectURL(fileURL);
        }
      })
      .catch((error) => {
        toast.error(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (openDialog) {
      generatePDF();
    }
  }, [openDialog]);
  return (
    <>
      <Row>
        <Col className="actionButtons text-center">
          <Link
            className={showViewOption ? "btn iconGreen" : "btn iconGrey"}
            to={`#`}
            onClick={() => {
              if (showViewOption) {
                setOpenDialog(true);
              }
            }}>
            <Tooltip
              title="View Payslip"
              placement="top">
              <VisibilityOutlinedIcon />
            </Tooltip>
          </Link>
        </Col>
      </Row>
      {!isMobile && (
        <ModelSelfFooter
          className="content container-fluid paySlipSection"
          open={openDialog}
          fullWidth={true}
          heading={<IntlMessages id="salary.slip.title" />}
          closePopUp={
            <Tooltip
              title="Close"
              placement="top">
              <CloseIcon
                fontSize="large"
                onClick={() => setOpenDialog(false)}
                className="popUpCloseIcon"
              />
            </Tooltip>
          }
          closeFn={() => setOpenDialog(false)}
          // maxWidth="xs"
        >
          <div style={{ width: "900rem" }}></div>
          {isLoading && <RctSectionLoader />}
          {!!pdfUrl ? (
            <iframe
              src={`${pdfUrl}`}
              width="100%"
              height="500px"
              allowFullScreen=""
              frameBorder="0"></iframe>
          ) : (
            <RctSectionLoader />
          )}
        </ModelSelfFooter>
      )}
    </>
  );
};

export default PaySlipPdf;
