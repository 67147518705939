import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { documentApi, documentDetailDownload } from "../../redux/ApiLists";
import { getActionsObject } from "../../util/Helper";
import CompactMenuWithActions from ".././shared/spinner/CompactMenuWithActions";
import DataTableWrapper from "../shared/dataTable/DataTableWrapper";
import ListTypes from "../shared/dataTable/ListTypes";
import { DOCUMENT_TYPES } from "../shared/Enums";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import AddDocumentDetail from "./AddDocumentDetail";

const DocumentDetailList = ({ filterDoc = [], entityId, documentType, listType, disable, disableAddAndDeleteButton }) => {
  DocumentDetailList.propTypes = {
    filterDoc: PropTypes.array.isRequired,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    documentType: PropTypes.string.isRequired,
    listType: PropTypes.string.isRequired,
    disable: PropTypes.bool.isRequired,
    disableAddAndDeleteButton: PropTypes.bool.isRequired,
  };
  const history = useHistory();
  const cubeModelName = "DocumentDetail";
  const [reload, reloadTable] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedRowId, setSelectedRowId] = useState();

  const refreshTable = () =>
    setTimeout(() => {
      reloadTable((prev) => !prev);
    }, 1500);
  useEffect(() => {
    if (selectedRowData?.rowData) {
      if (selectedRowData.clickedColumn == `${cubeModelName}.documentType`) {
        const documentType = selectedRowData.rowData[`${cubeModelName}.documentType`];
        const entityId = selectedRowData.rowData[`${cubeModelName}.entityId`];
        switch (documentType) {
          case DOCUMENT_TYPES.EMPLOYEE_DOCUMENT:
            return history.push({
              pathname: `/employeeDocuments`,
              search: `?eId=${entityId}`,
            });
          case DOCUMENT_TYPES.EDUCATION_DOCUMENT:
            return history.push({
              pathname: "/educationinfo",
              search: `?eId=${entityId}`,
            });
          case DOCUMENT_TYPES.EXPERIENCE_DOCUMENT:
            return history.push({
              pathname: "/experienceinfo",
              search: `?eId=${entityId}`,
            });
          case DOCUMENT_TYPES.LEAVE_RECORD_DOC:
            return history.push({
              pathname: "/leaves",
              search: `?eId=${entityId}`,
            });
          case DOCUMENT_TYPES.LEAVE_GRANT_DOC:
            return history.push({
              pathname: "/leave-grant",
              search: `?eId=${entityId}`,
            });
          case DOCUMENT_TYPES.ASSET_IMAGE:
            return history.push({
              pathname: "/assets",
              search: `?eId=${entityId}`,
            });
          case DOCUMENT_TYPES.COMPANY_DOCUMENT:
            return history.push({
              pathname: "/company-documents",
              search: `?eId=${entityId}`,
            });
          case DOCUMENT_TYPES.PARTY_DOCUMENT:
            return history.push({
              pathname: "/invoice-payment",
              search: `?eId=${entityId}`,
            });
          case DOCUMENT_TYPES.INVOICE_DOCUMENT:
            return history.push({
              pathname: "/invoice-payment",
              search: `?eId=${entityId}&docType=${DOCUMENT_TYPES.INVOICE_DOCUMENT}`,
            });
          case DOCUMENT_TYPES.REIMBURSEMENT_DOCUMENT:
            return history.push({
              pathname: "/reimbursements",
              search: `?eId=${entityId}`,
            });
        }
      }
    }
  }, [selectedRowData]);

  const dynamicFilters = [...filterDoc];

  const defaultFilter = {
    dimension: `${cubeModelName}.documentDetailId`,
    operator: "set",
    values: [``],
  };
  //default sort order
  const defaultSortOrder = [`${cubeModelName}.documentType`, "asc"];
  const columns = [
    {
      text: "ID",
      value: `${cubeModelName}.documentDetailId`,
      type: "string",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "File Name",
      value: `${cubeModelName}.fileName`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
    },
    {
      text: "Document Type",
      value: `${cubeModelName}.documentType`,
      type: "string",
      isDisplay: !listType,
      isVisible: !listType,

      isSorting: true,
      isSearchable: true,
      isClickable: true,
    },
    {
      text: "Entity Ref.",
      value: `${cubeModelName}.entityId`,
      type: "string",
      isDisplay: !listType,
      isVisible: !listType,

      isSorting: false,
    },
    {
      text: "Document Path",
      value: `${cubeModelName}.documentPath`,
      type: "string",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "Size(KB)",
      value: `${cubeModelName}.documentSize`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
    },
    {
      text: "cols.txt.action",
      value: "action_column",
      isDisplay: true,
      isVisible: false,
      isCustomCol: true,
      isSorting: false,
      style: { position: "sticky", right: "0px", backgroundColor: "#fefefe" },
    },
  ];

  const API = {
    delete: `${documentApi}`,
    download: `${documentDetailDownload}`,
  };
  const actionHtml = (rowData) => {
    const options = getActionsObject(rowData, cubeModelName);
    return (
      <Row>
        <Col className="actionButtons">
          <CompactMenuWithActions
            options={options}
            rowData={rowData}
            reloadTable={refreshTable}
            cubeModelName={cubeModelName}
            docName={rowData[`${cubeModelName}.fileName`]}
            showEditComponent={false}
            ShowDeleteComponent={!disable}
            showDownloadDocuments={true}
            ShowStatus={false}
            api={API}
            disableButton={disableAddAndDeleteButton}
            Id={rowData[`${cubeModelName}.documentDetailId`]}
            message={"Document"}
            cubeModelId={rowData[`${cubeModelName}.documentDetailId`]}
          />
        </Col>
      </Row>
    );
  };

  const customCols = [{ id: "action_column", component: actionHtml }];

  const customToolList = [
    entityId && !disableAddAndDeleteButton ? (
      <AddDocumentDetail
        entityId={entityId}
        reloadTable={refreshTable}
        iconTooltipId={`Add Document`}
        documentType={documentType}
        disable={disable}
      />
    ) : null,
  ];
  return (
    <>
      <TitleHeader pageTitle={"allFiles.menu.txt"} />
      <DataTableWrapper
        key={`key_document_list_${reload}`}
        cubeModelName={cubeModelName}
        listType={listType || ListTypes.documentDetailList}
        disabled={ListTypes.documentDetailList}
        defaultFilter={defaultFilter}
        columns={columns}
        toolsList={customToolList}
        defaultSortOrder={defaultSortOrder}
        customColumns={customCols}
        setSelectedRowData={setSelectedRowData}
        toolbarSize={2}
        selectedRowId={selectedRowId}
        dynamicFilters={dynamicFilters}
      />
    </>
  );
};

export default DocumentDetailList;
