import React from "react";
import PaySlipsRecordList from "./PaySlipsRecordList";

const PaySlipsRecordWrapper = () => {
  return (
    <>
      <PaySlipsRecordList />
    </>
  );
};

export default PaySlipsRecordWrapper;
