import React, { useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "../components/authentication/Login";
import Landingpage from "./LandingPage";
import PageNotFound from "./dashboard/pageNotFound";
import ToastMessage from "./shared/ToastMessage";

const WrapperComponent = () => {
  const [pageError, setPageError] = useState(false);
  return (
    <>
      <div className="WrapperComponent">
        <ToastMessage />
        <Router>
          <Switch>
            <Route
              exact={true}
              path="/login"
              component={Login}
            />
            {pageError && (
              <Route
                Path="/pageNotFound"
                component={PageNotFound}
              />
            )}
            <Landingpage setPageError={setPageError} />
          </Switch>
        </Router>
      </div>
    </>
  );
};
export default WrapperComponent;
