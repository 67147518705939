import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row, Table } from "reactstrap";
import ProfilePic from "../../components/profilePic/ProfilePic";
import { downloadPhoto } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
const ReportingManagerDetail = ({ openDialog, setOpenDialog, rowData }) => {
  ReportingManagerDetail.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
  };
  const reportingManagerSchema = "ReportingManager";

  const URLS = {
    download: `${downloadPhoto}`,
  };

  return (
    <>
      <ModelSelfFooter
        open={openDialog}
        heading={<IntlMessages id="title.reporting.manager" />}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              fontSize="large"
              onClick={() => setOpenDialog(false)}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="md"
        className="largePopUp">
        <Row className="profileImage">
          <Col className="myProfile">
            <div className="reportingManagerImage">
              <ProfilePic
                Id={rowData[`${reportingManagerSchema}.employeeId`]}
                forDefaultImg={true}
                shouldEdit={false}
                urls={URLS}
                withId={true}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table>
              <tbody>
                <tr>
                  <td>Reporting Manager Code</td>
                  <td>:</td>
                  <td>{rowData[`${reportingManagerSchema}.empCode`]}</td>
                </tr>
                <tr>
                  <td>Full Name</td>
                  <td>:</td>
                  <td>{rowData[`${reportingManagerSchema}.firstName`] + " " + rowData[`${reportingManagerSchema}.lastName`]}</td>
                </tr>
                <tr>
                  <td>Gender</td>
                  <td>:</td>
                  <td>{rowData[`${reportingManagerSchema}.gender`]}</td>
                </tr>
                <tr>
                  <td>Office Email</td>
                  <td>:</td>
                  <td>{rowData[`${reportingManagerSchema}.officeEmail`]}</td>
                </tr>
                <tr>
                  <td>Contact Number</td>
                  <td>:</td>
                  <td>{rowData[`${reportingManagerSchema}.contactNumber`]}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </ModelSelfFooter>
    </>
  );
};

export default ReportingManagerDetail;
