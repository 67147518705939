import React from "react";
import Swal from "sweetalert2";
import { AxiosInstance } from "../../config/AxiosConfig";
import PropTypes from "prop-types";

const DeleteButton = ({ api, Id, message, reloadTable, disableButton, axiosObject = undefined, innerButton }) => {
  DeleteButton.propTypes = {
    api: PropTypes.string.isRequired,
    Id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    reloadTable: PropTypes.func.isRequired,
    disableButton: PropTypes.bool,
    axiosObject: PropTypes.object,
    innerButton: PropTypes.node,
  };
  const axiosInstance = axiosObject || AxiosInstance;
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });
  const deleteUser = (id) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to delete this ${message}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await axiosInstance.delete(`${api.delete}/${Id}`).then((res) => {
            reloadTable((prev) => !prev);
          });
        }
      });
  };

  return (
    <>
      {innerButton ? (
        <Button
          color="outline-danger"
          onClick={deleteUser}
          className="buttonSpacingd">
          <IntlMessages id={`button.text.delete`} />
        </Button>
      ) : disableButton == true ? (
        <div style={{ marginLeft: "14px", fontSize: "14px", color: "#acabab" }}>Delete</div>
      ) : (
        <div
          style={{ marginLeft: "14px", fontSize: "14px" }}
          onClick={deleteUser}>
          Delete
        </div>
      )}
    </>
  );
};

export default DeleteButton;
