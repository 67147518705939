import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
/**
 *
 * @param {React.ElementType} children required
 * @param {string} heading modal heading
 * @param {React.ElementType} footer modal footer controls
 * @param {Function} closeFn modal required toogle
 * @param {Boolean} open required
 *
 */

const popUpTitleStyle = {
  borderBottom: "1px solid #e1e1e1",
};

export default function RctModel(props) {
  const { children, heading, subHeading, footer, closeFn, open, maxWidth, onSubmit, closePopUp } = props;

  return (
    <Dialog open={open} onClose={closeFn} aria-labelledby="responsive-dialog-title">
      <DialogTitle style={popUpTitleStyle}>
        {heading}
        {closePopUp}
        {subHeading}
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
      {footer && <DialogActions className="footerAction">{footer}</DialogActions>}
    </Dialog>
  );
}
