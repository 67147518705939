import PropTypes from "prop-types";
import React from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { AxiosInstanceForBlob } from "../../config/AxiosConfig";
import IntlMessages from "../../util/IntlMessages";

export const DownloadDocument = ({ Id, api, docName }) => {
  DownloadDocument.propTypes = {
    Id: PropTypes.string.isRequired,
    api: PropTypes.string.isRequired,
    docName: PropTypes.string.isRequired,
  };
  function getFileType(type) {
    let fileType;
    if (type == "jpeg" || type == "png" || type == "jpg") {
      fileType = `image/${type}`;
    }
    if (type == "pdf") {
      fileType = `application/${type}`;
    }
    return fileType;
  }

  const downloadEmployeeDocument = async () => {
    await AxiosInstanceForBlob.get(`${api.download}/${Id}`)
      .then((response) => {
        const fileType = response.headers["content-disposition"];
        if (fileType == null || fileType == undefined) {
          toast.error(<IntlMessages id="no.document.found.for.entity" />);
          return;
        }
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const file = new Blob([response.data], {
          type: getFileType(fileType),
        });
        const fileURL = URL.createObjectURL(file);
        a.href = fileURL;
        a.download = docName + "." + fileType;
        a.click();
        window.URL.revokeObjectURL(fileURL);
      })
      .catch((error) => {
        toast.error(<IntlMessages id="error.message.internalServer" />);
      });
  };
  return (
    <Row>
      {/* {!viewIcon && ( */}
      <Col>
        <div
          style={{ marginLeft: "14px", fontSize: "14px" }}
          className="download-doc"
          onClick={() => downloadEmployeeDocument()}>
          Download
        </div>
      </Col>
    </Row>
  );
};
