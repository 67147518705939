import { CubeContext } from "@cubejs-client/react";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { Col, Row, Table } from "reactstrap";
import { downloadEmployeePhoto } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";
import ProfilePic from "../profilePic/ProfilePic";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
const LoggedInUser = ({ openDialog, setOpenDialog }) => {
  LoggedInUser.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
  };
  const { cubejsApi } = useContext(CubeContext);

  const URLS = {
    download: `${downloadEmployeePhoto}`,
  };
  const employeeSchema = "EmployeeDetail";
  const accessToken = sessionStorage.getItem("token");
  const myProfile = JSON.parse(sessionStorage.getItem("profile"));
  const employeeID = myProfile.employee.employeeId;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const EMPLOYEE_DETAIL_QUERY = {
    limit: 1,
    dimensions: [`${employeeSchema}.employeeId`, `${employeeSchema}.imagePath`],
    filters: [
      {
        member: `${employeeSchema}.employeeId`,
        operator: "equals",
        values: [`${employeeID}`],
      },
    ],
  };

  const ROLES = [
    {
      label: "Portal Admin",
      role: "portal_admin",
    },
    {
      label: "Portal User",
      role: "portal_user",
    },
    {
      label: "Employee",
      role: "employee",
    },
  ];

  let roles = myProfile.roles.map((role) => {
    let roleObj = ROLES.find((obj) => obj.role == role);
    return roleObj.label;
  });

  const getData = async () => {
    setIsLoading(true);
    const myResponse = await cubejsApi
      .load(EMPLOYEE_DETAIL_QUERY)
      .then((resultSet) => {
        const response = resultSet.tablePivot().map((row) => {
          return {
            employeeId: row[`${employeeSchema}.employeeId`],
            imgPath: row[`${employeeSchema}.imagePath`],
          };
        });
        if (response?.length > 0) {
          setData(response);
        }
      })
      .catch((error) => {
        console.error("----> Errorrr ", error);
      });
    setIsLoading(false);
  };

  if (employeeID && accessToken) {
    useEffect(() => {
      getData();
    }, []);
  }

  return (
    <>
      <ModelSelfFooter
        open={openDialog}
        heading={<IntlMessages id="title.myProfile.text" />}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              fontSize="large"
              onClick={() => setOpenDialog(false)}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="md"
        className="largePopUp">
        <Row className="profileImage">
          <Col className="myProfile">
            {data.map((resPro, index) => {
              return (
                <>
                  {/* <img src={resPro.imgPath} alt="" width={70} height={80} /> */}
                  <div className="profile-img-1">
                    <ProfilePic
                      Id={resPro.employeeId}
                      shouldEdit={false}
                      urls={URLS}
                      reload={true}
                      forDefaultImg={true}
                      empPic={true}
                    />
                  </div>
                </>
              );
            })}
          </Col>
        </Row>
        <Row>
          <Col>
            <Table className="myProfileDataTable">
              <tbody>
                <tr>
                  <td className="tableColon">User Name</td>
                  <td>:</td>
                  <td>{myProfile.username}</td>
                </tr>
                <tr>
                  <td className="tableColon">First Name</td>
                  <td>:</td>
                  <td>{myProfile.firstName}</td>
                </tr>
                <tr>
                  <td className="tableColon">Last Name</td>
                  <td>:</td>
                  <td>{myProfile.lastName}</td>
                </tr>
                <tr>
                  <td className="tableColon">Email</td>
                  <td>:</td>
                  <td>{myProfile.email}</td>
                </tr>
                <tr>
                  <td className="tableColon">User Role</td>
                  <td>:</td>
                  <td>{roles.join(", ")}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </ModelSelfFooter>
    </>
  );
};

export default LoggedInUser;
