export const trimSchema = (rowdata) => {
  let trimmedObject = {};
  Object.keys(rowdata).map((key) => {
    let trimmedKey = key.split(".")[1];
    trimmedObject = { ...trimmedObject, [trimmedKey]: rowdata[key] };
  });
  return Object.keys(trimmedObject).length > 0 ? trimmedObject : undefined;
};

export const clearPreference = () => {
  localStorage.removeItem("sortOrder");
  localStorage.removeItem("pageNumber");
  localStorage.removeItem("rowsPerPage");
  localStorage.removeItem("viewColumns");
  localStorage.removeItem("filters");
};
export const setSortOrder = (listType, order) => {
  let sortOrder = JSON.parse(localStorage.getItem("sortOrder"));
  if (!sortOrder || typeof sortOrder === "undefined") {
    sortOrder = {};
  }
  sortOrder[listType] = order;
  localStorage.setItem("sortOrder", JSON.stringify(sortOrder));
};

export const getSortOrder = (listType, defaultOrder) => {
  let sortOrder = JSON.parse(localStorage.getItem("sortOrder"));
  if (!sortOrder || typeof sortOrder === "undefined" || typeof sortOrder[listType] === "undefined") {
    setSortOrder(listType, defaultOrder);
    return defaultOrder;
  }
  return sortOrder[listType];
};

export const setPageInStorage = (listType, page) => {
  let pageNumber = JSON.parse(localStorage.getItem("pageNumber"));
  if (!pageNumber || typeof pageNumber === "undefined") {
    pageNumber = {};
  }
  pageNumber[listType] = page;
  localStorage.setItem("pageNumber", JSON.stringify(pageNumber));
};

export const getPageFromStorage = (listType, defaultPage) => {
  let pageNumber = JSON.parse(localStorage.getItem("pageNumber"));
  if (!pageNumber || typeof pageNumber === "undefined" || typeof pageNumber[listType] === "undefined") {
    setPageInStorage(listType, defaultPage);
    return defaultPage;
  }
  return pageNumber[listType];
};

export const setRowsPerPageInStorage = (listType, row) => {
  let rowsPerPage = JSON.parse(localStorage.getItem("rowsPerPage"));
  if (!rowsPerPage || typeof rowsPerPage === "undefined") {
    rowsPerPage = {};
  }
  rowsPerPage[listType] = row;
  localStorage.setItem("rowsPerPage", JSON.stringify(rowsPerPage));
};

export const getRowsPerPageFromStorage = (listType, defaultRowsPerPage) => {
  let rowsPerPage = JSON.parse(localStorage.getItem("rowsPerPage"));
  if (!rowsPerPage || typeof rowsPerPage === "undefined" || typeof rowsPerPage[listType] === "undefined") {
    setRowsPerPageInStorage(listType, defaultRowsPerPage);
    return defaultRowsPerPage;
  }
  return rowsPerPage[listType];
};

export const setViewColumns = (listType, selectedList) => {
  let viewCols = JSON.parse(localStorage.getItem("viewColumns"));
  if (!viewCols || typeof viewCols === "undefined") {
    viewCols = {};
  }
  viewCols[listType] = selectedList;
  localStorage.setItem("viewColumns", JSON.stringify(viewCols));
};

export const getViewColumns = (listType, defaultList) => {
  let viewCols = JSON.parse(localStorage.getItem("viewColumns"));
  if (!viewCols || typeof viewCols === "undefined" || typeof viewCols[listType] === "undefined") {
    setViewColumns(listType, defaultList);
    return defaultList;
  }
  return viewCols[listType];
};

export const setFilters = (listType, newFilters) => {
  let filters = JSON.parse(localStorage.getItem("filters"));
  if (!filters || typeof filters === "undefined") {
    filters = {};
  }
  if (!filters[listType] || typeof filters[listType] === "undefined" || typeof filters[listType] !== "object") {
    filters[listType] = [];
  }
  if (newFilters instanceof Array) {
    filters[listType] = [];
    filters[listType] = [...newFilters];
  } else {
    filters[listType] = [newFilters];
  }

  localStorage.setItem("filters", JSON.stringify(filters));
};

export const getFilters = (listType, defaultFilters) => {
  let filters = JSON.parse(localStorage.getItem("filters"));
  if (!filters || typeof filters === "undefined" || typeof filters[listType] === "undefined") {
    setFilters(listType, defaultFilters);
    if (defaultFilters instanceof Array) return [...defaultFilters];
    return [defaultFilters];
  }
  return filters[listType];
};

export const getCurrentFilterValue = (listType, dimension, defaultValue) => {
  let filters = JSON.parse(localStorage.getItem("filters"));
  if (!filters || typeof filters === "undefined" || !filters[listType] || typeof filters[listType] === "undefined" || typeof filters[listType] !== "object") {
    return defaultValue;
  }
  let findObj = filters[listType].find((x) => x.dimension === dimension);
  if (findObj) {
    return findObj.values[0];
  }
  return defaultValue;
};
