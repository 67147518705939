import AddIcon from "@material-ui/icons/Add";
import { Grid, IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import IntlMessages from "../../../../util/IntlMessages";

const AddEntityWrapper = ({ reloadTable, iconTooltipId, popupComponent: Component, ...rest }) => {
  AddEntityWrapper.propTypes = {
    reloadTable: PropTypes.func.isRequired,
    iconTooltipId: PropTypes.string.isRequired,
    popupComponent: PropTypes.elementType.isRequired,
  };
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <Grid
      container
      justify="center"
      item
      lg={1}
      sm={1}
      xl={1}
      xs={2}
      m={1}>
      <Tooltip title={<IntlMessages id={iconTooltipId} />}>
        <IconButton
          id={`add-btn-${iconTooltipId}`}
          onClick={() => setOpenDialog(true)}>
          <AddIcon className="add-icon" />
        </IconButton>
      </Tooltip>
      <span className="tableActionButton">
        {openDialog && (
          <Component
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            reloadTable={reloadTable}
            projectInfo={rest?.projectName}
          />
        )}
      </span>
    </Grid>
  );
};
export default AddEntityWrapper;
