import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DataTableWrapper from "../shared/dataTable/DataTableWrapper";
import ListTypes from "../shared/dataTable/ListTypes";

import TitleHeader from "../shared/pageTitle/TitleHeader";

const TeamList = () => {
  const cubeModelName = "Employee";
  const [reload, reloadTable] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [reportingManagerId, setReportingManagerId] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      setReportingManagerId(location?.state);
    }
  }, []);

  const profile = JSON.parse(sessionStorage.getItem("profile"));
  const employeeID = profile.employee.employeeId;

  const defaultFilter = {
    dimension: `${cubeModelName}.reportingManagerId`,
    operator: "equals",
    values: [`${employeeID}`],
  };

  //default sort order
  const defaultSortOrder = [`${cubeModelName}.empCode`, "asc"];
  const columns = [
    {
      text: "ID",
      value: `${cubeModelName}.employeeId`,
      type: "number",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
      // isNotPrintable: true,
    },
    {
      text: "EmpCodeNumber",
      value: `${cubeModelName}.empCodeNumber`,
      type: "number",
      isDisplay: false,
      isVisible: false,
      isSorting: true,
    },
    {
      text: "cols.txt.employeeFullName",
      value: `${cubeModelName}.empName`,
      type: "string",
      isDisplay: true,
      isVisible: false,
      isSorting: true,
      isSearchable: true,
      isClickable: true,
      isEmpPic: true,
    },
    {
      text: "employeeList.txt.empCode",
      value: `${cubeModelName}.empCode`,
      type: "string",
      isDisplay: true,
      isVisible: false,
      isSorting: true,
      isSearchable: true,
      isClickable: true,
      style: { minWidth: "8rem", maxWidth: "8rem" },
    },
    {
      text: "employeeList.txt.designation",
      value: `${cubeModelName}.designation`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      style: { minWidth: "12rem", maxWidth: "12rem" },
    },
    {
      text: "employeeList.cols.txt.gender",
      value: `${cubeModelName}.gender`,
      type: "string",
      isDisplay: false,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
      defaultVal: "--",
      style: { minWidth: "10rem", maxWidth: "10rem" },
    },
    {
      text: "employeeList.txt.dob",
      value: `${cubeModelName}.dob`,
      type: "date",
      isDisplay: false,
      isVisible: true,
      isSorting: true,
      style: { minWidth: "12rem", maxWidth: "12rem" },
    },
    {
      text: "employeeList.cols.txt.contact",
      value: `${cubeModelName}.contactNumber`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
      defaultVal: "--",
      style: { minWidth: "14rem", maxWidth: "14rem" },
    },
    {
      text: "employeeList.cols.txt.officeEmail",
      value: `${cubeModelName}.officeEmail`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
      defaultVal: "--",
      style: { minWidth: "14rem", maxWidth: "14rem" },
    },
  ];

  const filterOptions = [];

  const customCols = [];
  const customToolList = [];

  return (
    <>
      <TitleHeader
        shouldShowArrow={false}
        title="HRMS-employee"
        pageTitle={"title.teams"}
        shouldShowBackArrow={true}
      />
      <DataTableWrapper
        key={`key_employee_list_${reload}`}
        cubeModelName={cubeModelName}
        listType={ListTypes.teamList}
        defaultFilter={defaultFilter}
        columns={columns}
        toolsList={customToolList}
        defaultSortOrder={defaultSortOrder}
        customColumns={customCols}
        setSelectedRowData={setSelectedRowData}
        toolbarSize={12}
        filterOptions={filterOptions}
      />
    </>
  );
};

export default TeamList;
