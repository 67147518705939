import styled from "styled-components";

const NavBarDesign = styled.div`
  justify-content: space-between;
  display: flex;
  box-shadow: 0px 1px 10px -1px rgb(0 0 0 / 20%) !important;
  margin-bottom: 25px;
  padding: 10px;
  position: sticky;
  z-index: 999;
  top: 0;
  align-items: center;
  background: #fff;
  height: 100%;
`;

const BtnLogoutDesign = styled.div`
  justify-self: end;
  margin-right: 1em;
`;

const ToggleDesign = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`;

const ProfileImage = styled.div`
  width: 50px;
  display: inline-block;
`;

export { BtnLogoutDesign, NavBarDesign, ProfileImage, ToggleDesign };
