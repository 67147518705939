import PropTypes from "prop-types";
import IntlMessages from "../../../util/IntlMessages";

const StatusColumnUI = ({ status, statusTypeList }) => {
  StatusColumnUI.propTypes = {
    status: PropTypes.string.isRequired,
    statusTypeList: PropTypes.object.isRequired,
  };
  let obj = statusTypeList?.find((s) => {
    return s.status == status;
  });
  return obj ? (
    <span
      className={`renderStatus`}
      style={{ background: obj.bgColor, color: obj.color ? obj.color : "white" }}>
      {" "}
      <IntlMessages id={`${obj.label}`} />
    </span>
  ) : (
    <></>
  );
};
export default StatusColumnUI;
