import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { AxiosInstance } from "../../config/AxiosConfig";
import { applyLeaveAgainstLeaveGrantApi } from "../../redux/ApiLists";
import useCalculateDaysOff from "../../util/CalculateDaysOff";
import { removeTimeFromDate } from "../../util/Helper";
import IntlMessages from "../../util/IntlMessages";

const ApplyLeaveAgainstLeaveGrant = ({ popCloseFunction, reload, rowData }) => {
  ApplyLeaveAgainstLeaveGrant.propTypes = {
    popCloseFunction: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
  };
  const [loading, setLoading] = useState(false);
  let calculateDaysOff = useCalculateDaysOff();
  const [daysOff, setDaysOff] = useState(0);
  let daysGranted = rowData["LeaveGrant.daysGrant"];
  const leaveGrantId = rowData["LeaveGrant.leaveGrantId"];
  let validationSchema = yup.object().shape({
    leaveStartDate: yup.string().trim().required(""),
    leaveEndDate: yup.string().trim().required(""),
  });

  const popUpTitleStyle = {
    borderBottom: "1px solid #e1e1e1",
    background: "#fafafa",
  };

  function applyLeaveHandler(formValues) {
    setLoading(true);
    AxiosInstance.post(`${applyLeaveAgainstLeaveGrantApi}/${leaveGrantId}`, formValues)
      .then((res) => {
        setLoading(false);
        reload((prev) => !prev);
        popCloseFunction(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const formik = useFormik({
    initialValues: {
      leaveGrantId: rowData["LeaveGrant.leaveGrantId"],
      leaveStartDate: "",
      leaveEndDate: "",
      leaveStatus: "Applied",
    },
    onSubmit: (values) => {
      applyLeaveHandler(values);
    },
    validationSchema: validationSchema,
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  useEffect(() => {
    let leaveStartDate = formik.values.leaveStartDate;
    let leaveEndDate = formik.values.leaveEndDate;
    if (leaveStartDate && leaveEndDate) {
      calculateDaysOff(leaveStartDate, leaveEndDate).then((res) => {
        setDaysOff(res);
        if (res > daysGranted) {
          toast.error(
            <IntlMessages
              id="days.grant.error.message"
              values={{ days: daysGranted }}
            />
          );
        }
      });
    }
  }, [formik.values.leaveStartDate, formik.values.leaveEndDate]);

  return (
    <Dialog
      open={true}
      fullWidth={true}
      className="asset-assign-popUp">
      <DialogTitle style={popUpTitleStyle}>
        {<IntlMessages id="leave-grant-redeemed-action-title" />}
        {
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              onClick={() => {
                popCloseFunction(false);
              }}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
      </DialogTitle>
      <DialogContent>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="assetsForm"
          onSubmit={formik.handleSubmit}>
          <div className="formBlock">
            <Row>
              <Col
                sm={6}
                xs={12}>
                <FormGroup>
                  <Label htmlFor="leaveStartDate">
                    <IntlMessages id="leave.grant.leaveStartDate" />
                  </Label>
                  <Input
                    type="date"
                    name="leaveStartDate"
                    id="leaveStartDate"
                    value={formik.values.leaveStartDate}
                    onChange={formik.handleChange}
                    className="input-field-css"
                    invalid={formik.touched.leaveStartDate && !formik.values.leaveStartDate}
                    onBlur={formik.handleBlur}
                  />
                  {renderErrorMessage("leaveStartDate")}
                </FormGroup>
              </Col>
              <Col
                sm={6}
                xs={12}>
                <FormGroup>
                  <Label htmlFor="leaveEndDate">
                    <IntlMessages id="leave.grant.leaveEndDate" />
                  </Label>
                  <Input
                    type="date"
                    name="leaveEndDate"
                    id="leaveEndDate"
                    value={formik.values.leaveEndDate}
                    onChange={formik.handleChange}
                    className="input-field-css"
                    invalid={formik.touched.leaveEndDate && !formik.values.leaveEndDate}
                    onBlur={formik.handleBlur}
                    min={formik.values.leaveStartDate}
                    max={removeTimeFromDate(rowData["LeaveGrant.expirationDate"])}
                  />
                  {renderErrorMessage("leaveEndDate")}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col
                sm={6}
                xs={12}>
                <FormGroup>
                  <Label htmlFor="daysOff">
                    <IntlMessages id="leave.grant.daysOff" />
                  </Label>
                  <Input
                    type="text"
                    name="daysOff"
                    id="daysOff"
                    value={daysGranted}
                    disabled></Input>
                </FormGroup>
              </Col>
              <Col
                sm={6}
                xs={12}>
                <FormGroup>
                  <Label htmlFor="leaveStatus">
                    <IntlMessages id="leave.grant.leaveStatus" />
                  </Label>
                  <Input
                    type="text"
                    name="leaveStatus"
                    id="leaveStatus"
                    value={formik.values.leaveStatus}
                    disabled></Input>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <DialogActions>
            <Row className="FooterForm">
              <Col>
                <Button
                  variant="contained"
                  color="outline-secondary"
                  onClick={() => {
                    popCloseFunction(false);
                  }}
                  className="buttonSpacing">
                  <IntlMessages id={`button.text.cancel`} />
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className="buttonSpacing"
                  disabled={loading || daysOff > daysGranted}>
                  {loading && (
                    <i
                      className="fa fa-refresh fa-spin"
                      style={{ marginRight: "5px" }}
                    />
                  )}
                  {loading && <IntlMessages id={`button.text.save`} />}
                  {!loading && <IntlMessages id={`button.text.save`} />}
                </Button>
              </Col>
            </Row>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ApplyLeaveAgainstLeaveGrant;
