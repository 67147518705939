import { AiOutlineCalendar, AiOutlineFileDone, AiOutlineFundProjectionScreen, AiOutlineLaptop } from "react-icons/ai";
import { BiBuildingHouse, BiRupee } from "react-icons/bi";
import { FaUserShield } from "react-icons/fa";
import { MdOutlineDashboard } from "react-icons/md";
import { IoMdSchool } from "react-icons/io";

const MenuList = [
  {
    title: "title.dashboard",
    path: "/dashboard",
    icon: <MdOutlineDashboard />,
  },
  {
    title: "title.profile",
    path: "/my-profile",
    icon: <BiBuildingHouse />,
  },
  {
    title: "title.leaves",
    path: "/leaves",
    icon: <AiOutlineCalendar />,
  },
  {
    title: "title.leaveGrant",
    path: "/leave-grant",
    icon: <AiOutlineCalendar />,
  },
  {
    title: "title.educationInfo",
    path: "/educationInfo",
    icon: <IoMdSchool />,
  },
  {
    title: "title.assets",
    path: "/assets",
    icon: <AiOutlineLaptop />,
  },
  {
    title: "title.projects",
    path: "/projects",
    icon: <AiOutlineFundProjectionScreen />,
  },
  {
    title: "title.myDocuments",
    path: "/my-documents",
    icon: <AiOutlineFileDone />,
  },
  {
    title: "title.policies",
    path: "/company-documents",
    icon: <AiOutlineFileDone />,
  },
  {
    title: "title.insurance",
    path: "/insurance",
    icon: <FaUserShield />,
  },
  {
    title: "title.payslips",
    path: "/payslips",
    icon: <BiRupee />,
  },
];

export default MenuList;
