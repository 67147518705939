import ListTypes from "../shared/dataTable/ListTypes";
// mapping of api names to CubeModelName
export const exportApiPath = {
  [ListTypes.employeeList]: "employee",
  [ListTypes.assetList]: "asset",
  [ListTypes.projectList]: "project",
  [ListTypes.ctcList]: "ctc",
};
export const leaveStatus = [
  { label: "leaveStatus.approved.text", status: "Approved", bgColor: "#07bc0c" },
  { label: "leaveStatus.rejected.text", status: "Rejected", bgColor: "#767674" },
  { label: "leaveStatus.cancelled.text", status: "Cancelled", bgColor: "#ff0000" },
  { label: "leaveStatus.applied.text", status: "Applied", bgColor: "#efff68", color: "#333" },
];
export const GrantStatus = [  
  { label: "grantStatus.grantApplied.text", status: "Grant Applied", bgColor: "#0D6EFD" },
  { label: "grantStatus.grantApproved.text", status: "Grant Approved", bgColor: "#07BC0C" },
  { label: "grantStatus.grantCancelled.text", status: "Grant Cancelled", bgColor: "#EE4B2B" },
  { label: "grantStatus.leaveApplied.text", status: "Leave Applied", bgColor: "#0D6EFD" },
  { label: "grantStatus.leaveApproved.text", status: "Leave Approved", bgColor: "#07BC0C" },
  { label: "grantStatus.leaveCancelled.text", status: "Leave Cancelled", bgColor: "#EE4B2B" },
];
export const projectResourceStatus = [
  { label: "projectResourceStatus.assigned.text", status: "Assigned", bgColor: "#07bc0c" },
  { label: "projectResourceStatus.released.text", status: "Released", bgColor: "#ff0000" },
];
export const assetStatus = [
  { label: "assetStatus.assigned.text", status: "Assigned", bgColor: "#07bc0c" },
  { label: "assetStatus.reserved.text", status: "Reserved", bgColor: "#767674" },
  { label: "assetStatus.maintenance.text", status: "Maintenance", bgColor: "#ff0000" },
  { label: "assetStatus.available.text", status: "Available", bgColor: "#efff68", color: "#333" },
];
export const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const LEAVE_TYPES = {
  ANNUAL_LEAVE: "Annual Leave",
  CASUAL_LEAVE: "Casual Leave",
  SICK_LEAVE: "Sick Leave",
  MATERNITY_LEAVE: "Maternity Leave",
  PATERNITY_LEAVE: "Paternity Leave",
  COMPENSATORY_LEAVE: "Compensatory Leave",
  UNPAID_LEAVE: "Unpaid Leave",
};

export const LEAVE_RECORD = {
  leaveType: "LeaveRecord.leaveType",
  daysOff: "LeaveRecord.daysOff",
};
export const LEAVE = {
  FULL_DAY: "Leave",
  FIRST_HALF: "First Half",
  SECOND_HALF: "Second Half",
};
export const GrantStatusEnum = {
  GRANT_APPLIED:"Grant Applied",
    GRANT_APPROVED:"Grant Approved",
    GRANT_CANCELLED:"Grant Cancelled",
    LEAVE_APPLIED:"Leave Applied",
    LEAVE_NOT_APPLIED:"Leave Not Applied",
    LEAVE_APPROVED:"Leave Approved",
    LEAVE_CANCELLED:"Leave Cancelled"
};
export const DOCUMENT_TYPES = {
  PROFILE_PHOTO: "profile-photo",
  COMPANY_LOGO: "company-logo",
  COMPANY_DOCUMENT: "company-document",
  EMPLOYEE_DOCUMENT: "employee-document",
  PARTY_DOCUMENT: "party-document",
  INVOICE_DOCUMENT: "invoice-document",
  EXPERIENCE_DOCUMENT: "experience-document",
  EDUCATION_DOCUMENT: "education-document",
  ASSET_IMAGE: "asset-image",
  LEAVE_RECORD_DOC: "leave-record",
  COMPENSATORY_OFF: "compensatory-off",
  REIMBURSEMENT_DOCUMENT: "reimbursement-document",
  LEAVE_GRANT_DOC: "leave-grant",
  PROJECT_DOC: "project-document",
};
