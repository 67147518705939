// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.companyMenuNav {
  border: none;
  background: no-repeat;
  flex-basis: 40%;
  text-align: right;
}
.companyMenuNav .MuiButton-text {
  color: rgb(0, 7, 61);
  text-transform: capitalize;
  font-family: "Poppins", sans-serif !important;
}

.appSettingMenu .MuiMenu-list {
  background: #0098da;
  padding-left: 0 !important;
}
.appSettingMenu .MuiMenu-list li a {
  text-decoration: none;
  line-height: 18px;
  color: #fff;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
}
.appSettingMenu .MuiMenu-list li:hover {
  background-color: #42a8d4;;
}
.appSettingMenu {
  top: 55px !important;
}
.companyMenuNav .MuiButton-text {
  transition: none;
}
.companyMenuNav .MuiButton-text:focus,
.companyMenuNav .MuiButton-text:hover {
  background: none;
  transition: none;
}
a.modify-link {
  text-decoration: none;
  color: aliceblue;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/navbar/appSettingMenu.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qBAAqB;EACrB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,oBAAoB;EACpB,0BAA0B;EAC1B,6CAA6C;AAC/C;;AAEA;EACE,mBAAmB;EACnB,0BAA0B;AAC5B;AACA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,6CAA6C;AAC/C;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,oBAAoB;AACtB;AACA;EACE,gBAAgB;AAClB;AACA;;EAEE,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,qBAAqB;EACrB,gBAAgB;AAClB","sourcesContent":[".companyMenuNav {\n  border: none;\n  background: no-repeat;\n  flex-basis: 40%;\n  text-align: right;\n}\n.companyMenuNav .MuiButton-text {\n  color: rgb(0, 7, 61);\n  text-transform: capitalize;\n  font-family: \"Poppins\", sans-serif !important;\n}\n\n.appSettingMenu .MuiMenu-list {\n  background: #0098da;\n  padding-left: 0 !important;\n}\n.appSettingMenu .MuiMenu-list li a {\n  text-decoration: none;\n  line-height: 18px;\n  color: #fff;\n  font-size: 15px;\n  font-family: \"Poppins\", sans-serif !important;\n}\n.appSettingMenu .MuiMenu-list li:hover {\n  background-color: #42a8d4;;\n}\n.appSettingMenu {\n  top: 55px !important;\n}\n.companyMenuNav .MuiButton-text {\n  transition: none;\n}\n.companyMenuNav .MuiButton-text:focus,\n.companyMenuNav .MuiButton-text:hover {\n  background: none;\n  transition: none;\n}\na.modify-link {\n  text-decoration: none;\n  color: aliceblue;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
