import { GET_ALLENUMS_REQUEST, GET_BIRTHDAYS_REQUEST, GET_EVENTS_REQUEST, GET_HOLIDAYS_REQUEST, GET_LINKS_REQUEST, UPDATE_DASHBOARD_FLAG } from "./type";

export const getAllEnumsAction = () => {
  return {
    type: GET_ALLENUMS_REQUEST,
  };
};
export const getAllBirthDaysAction = () => {
  return {
    type: GET_BIRTHDAYS_REQUEST,
  };
};

export const getHolidayAction = () => {
  return {
    type: GET_HOLIDAYS_REQUEST,
  };
};

export const getLinksAction = () => {
  return {
    type: GET_LINKS_REQUEST,
  };
};
export const getEventsAction = () => {
  return {
    type: GET_EVENTS_REQUEST,
  };
};
export const updateDashboardFlagAction = (payload) => {
  return {
    type: UPDATE_DASHBOARD_FLAG,
    payload,
  };
};
