import { Logout } from "@mui/icons-material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import LockResetIcon from "@mui/icons-material/LockReset";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import { FaBars } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { NavBarDesign } from "../../../constants/StyleNavBar";
import { companyDetailApi, getCompanyLogo, logoutApi } from "../../../redux/ApiLists";
import ChangePassword from "../../authentication/ChangePassword";
import LoggedInUser from "../../myProfile/LoggedInUser";
import ProfilePic from "../../profilePic/ProfilePic";
import AppSettingMenu from "./AppSettingMenu";
import PresenceStatus from "./PresenceStatus";

const Header = ({ toggleSideBar, isOpen, breakPoint, ResponsiveWdth }) => {
  Header.propTypes = {
    toggleSideBar: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    breakPoint: PropTypes.number.isRequired,
    ResponsiveWdth: PropTypes.number.isRequired,
  };
  const [company, setCompanyDetail] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openChangePassword, setChangePassword] = useState(false);

  const profile = JSON.parse(sessionStorage.getItem("profile"));
  const userName = profile?.username;

  const logout = (token) => {
    let accessToken = sessionStorage.getItem("token");
    Axios.post(`${process.env.REACT_APP_ERMS_ADMIN_API}${logoutApi}?username=${encodeURIComponent(userName)}`, null, {
      headers: {
        authorization: "Bearer " + accessToken,
        contentType: "application/json",
      },
    })
      .then((response) => {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = "/login";
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [anchorE2, setAnchorE2] = useState(null);
  const openMenu = Boolean(anchorE2);
  const handleClickMenu = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMyProfileClick = () => {
    setOpenDialog(true);
    handleClose();
  };
  const onChangePasswordClick = () => {
    setChangePassword(true);
    handleClose();
  };
  const URLS = {
    download: `${getCompanyLogo}`,
  };
  const companyInfo = JSON.parse(sessionStorage.getItem("profile"));
  const companyCode = companyInfo.attributes.company_code;

  const getLoggedInCompanyDetails = async () => {
    await Axios.get(`${process.env.REACT_APP_ERMS_ADMIN_API}${companyDetailApi}/${companyCode}`)
      .then((result) => {
        setCompanyDetail(result.data.data);
      })
      .catch((error) => {
        toast.error(error?.message);
      }, []);
  };
  const loadCompanyLogo = useSelector((store) => {
    return store?.employeeReducer?.isLoadProfilePic;
  });
  useEffect(() => {
    getLoggedInCompanyDetails();
  }, []);

  return (
    <div className="NavBarMenu">
      <NavBarDesign>
        {ResponsiveWdth < breakPoint &&
          (() => {
            if (isOpen) {
              return (
                <div
                  className="barsMobile closeIcon"
                  onClick={toggleSideBar}>
                  <AiIcons.AiOutlineClose />
                </div>
              );
            } else if (isOpen == false) {
              return (
                <div
                  className="barsMobile closeIcon"
                  onClick={toggleSideBar}>
                  <FaBars />
                </div>
              );
            }
          })()}
        {ResponsiveWdth > breakPoint && (
          <div className="toggleFullWidth">
            <div
              className="barsMobile"
              onClick={toggleSideBar}>
              <FaBars />
            </div>
          </div>
        )}

        <div className="brandLogo">
          <div className="companyLogoInnerContent">
            <div className="companyLogoArea">
              <ProfilePic
                Id={companyCode}
                shouldEdit={false}
                urls={URLS}
                load={true}
                loadCompanyLogo={loadCompanyLogo}
                forCompanyDefault={true}
              />
            </div>
          </div>
        </div>

        <div className="companyMenuNav">
          <Button
            id="demo-positioned-button"
            aria-controls={openMenu ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            onClick={handleClickMenu}>
            {window.innerWidth > 425 && company?.companyName}
            {openMenu ? <KeyboardArrowUpOutlinedIcon className="muiIconStyling" /> : <KeyboardArrowDownOutlinedIcon className="muiIconStyling" />}
          </Button>
          <AppSettingMenu
            anchorE2={anchorE2}
            openMenu={openMenu}
            setAnchorE2={setAnchorE2}
          />
        </div>

        <PresenceStatus />
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              top: "60px",
            }}>
            <Tooltip title="Account settings">
              <IconButton
                className=""
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}>
                <Avatar
                  className="bgColor position"
                  sx={{ width: 40, height: 40 }}>
                  {profile.firstName.substring(0, 1)}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            className="myProfilePopup">
            <MenuItem className="justify-content-center">
              <Avatar className="bgColor">{profile.firstName.substring(0, 1)}</Avatar>
            </MenuItem>
            <div
              className="position"
              style={{ cursor: "default" }}>
              {profile.firstName + " " + profile.lastName}
            </div>
            <Divider />
            <MenuItem onClick={onMyProfileClick}>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              My Profile
            </MenuItem>
            <MenuItem style={{ cursor: "default" }}>
              <ListItemIcon>
                <MailOutlineIcon />
              </ListItemIcon>
              {profile.email}
            </MenuItem>
            <MenuItem onClick={onChangePasswordClick}>
              <ListItemIcon>
                <LockResetIcon />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </React.Fragment>
      </NavBarDesign>
      {openDialog && (
        <LoggedInUser
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      )}
      {openChangePassword && (
        <ChangePassword
          openChangePassword={openChangePassword}
          setChangePassword={setChangePassword}
        />
      )}
    </div>
  );
};
export default Header;
