export default {
  defaultHeader: "HRMS Admin",

  // Dashboard Card Title
  "dashboard.card.title.holidays": "Holidays",
  "dashboard.card.title.birthdays": "Birthdays",
  "dashboard.card.title.importantLinks": "Important Links",
  "dashboard.card.title.companyEvents": "Company Events",
  "dashboard.punchStatus.toolTip": "presence status",
  "dashboard.punchStatus.title": "Mark your presence status?",
  "dashboard.punchStatus.inBtn": "IN",
  "dashboard.punchStatus.outBtn": "OUT",
  "dashboard.punchStatus.allowLocationMsg": "please allow location permission",
  "dashboard.punchStatus.notInPremisesMsg": "seems like you are not available in premises",

  // Sidebar Menu
  "title.dashboard": "Dashboard",
  "title.profile": "Profile",
  "title.address": "Address",
  "title.bankDetail": "Bank Detail",
  "title.leaves": "Leaves",
  "title.leaveGrant": "Leave Grant",
  "title.educationInfo": "Education Info",
  "title.assets": "Assets",
  "title.projects": "Projects",
  "title.myDocuments": "My Documents",
  "title.companyDocuments": "Company Documents",
  "title.policies": "Policies",
  "title.insurance": "Insurance",
  "title.payslips": "Payslips",

  //preference
  "heading.text.clearPreference": "Clear Preference",
  "warning.text.clearPreference": "Clear Preference will Reset settings(ie: page number, rows per page, sort orders, view columns and filters) for all the Tables",
  "button.text.clearPreference": "Clear",

  // Button's
  "button.text.save": "Save",
  "button.text.cancel": "Cancel",

  // Apply Leaves
  "icon.apply.leave": "Apply Leave",
  "leaveRecord.cols.txt.fromDate": "From Date",
  "leaveRecord.cols.txt.toDate": "To Date",
  "leaveRecord.cols.txt.leaveType": "Leave Type",
  "leaveRecord.cols.txt.daysOff": "Days Off",
  "leaveRecord.cols.txt.leaveReason": "Leave Reason",
  "leaveRecord.cols.txt.comment": "Comment",
  "leaveRecord.cols.txt.leaveInterval": "Leave Interval",
  "leaveRecord.cols.txt.leaveStatus": "Status",
  "leaveStatus.approved.text": "Approved",
  "leaveStatus.applied.text": "Applied",
  "leaveStatus.rejected.text": "Rejected",
  "leaveStatus.cancelled.text": "Cancelled",

  // Leave Grant
  "leave-grant-redeemed-action-title": "Redeem Leave Grant",
  "leave-grant-title": "Leave Grant",
  "leave-grant-add": "Add Leave Grant",
  "leave-grant-update": "Update Leave Grant",
  "leave-grant-employee-name": "Employee Name",
  "leave-grant-expirationDate": "Expiration Date",
  "leave-grant-grantReason": "Grant Reason",
  "leave-grant-daysGrant": "Days Grant",
  "leave-grant-leaveGrantType": "Leave Grant Type",
  "leave-grant-status": "Grant Status",
  "leave-grant-fromDate": "From Date",
  "leave-grant-toDate": "To Date",
  "leave-grant-comment": "Comment",
  "leave.grant.leaveStatus": "Leave Status",
  "leave.grant.leaveStartDate": "Leave Start Date",
  "leave.grant.leaveEndDate": "Leave End Date",
  "leave.grant.delete.success": "Leave grant delete successfully",
  "leave-grant-documentPath": "Document",
  "form.text.empDocument.docType": "[.doc, .docs, .xlsx, .pdf, .txt, .xls, .jpg, .jpeg, .png]",
  "days.grant.error.message": "Days-Off can not be greater than DaysGranted. Employee have only {days} days Granted",
  "leave.grant.daysOff": "Days-Off",
  "leave-cancelled": "Cancel Leave",
  "leave-approved": "Approve Leave",
  "leave-applied": "Apply Leave",
  "leave-grant-cancelled": "Grant Cancel",
  "leave-grant-approved": "Approve Grant",

  //Grant Status Enums
  "grantStatus.grantApplied.text": "Grant Applied",
  "grantStatus.grantApproved.text": "Grant Approved",
  "grantStatus.grantCancelled.text": "Grant Cancelled",
  "grantStatus.leaveCancelled.text": "Leave Cancelled",
  "grantStatus.leaveApproved.text": "Leave Approved",
  "grantStatus.leaveApplied.text": "Leave Applied",
  "grantStatus.cancelled.text": "Leave Not Applied",
  // Form Text
  "form.text.registration": "Registration",
  "forget.password.newPassword": "New Password",
  "forget.password.confirmPassword": "Confirm Password",

  // API Response Message
  "axios.api.success.message": "Saved Successfully",
  "axios.api.update.message": "Updated Successfully",
  "axios.api.deleted.message": "Deleted Successfully",
  "axios.api.cancel.message": "Cancelled Successfully",
  "file.type.not.support": "This File Type Not Supported",
  "doc.type.not.support": "Document type not supported. Only pdf,jpg,png file can view here.",

  // My Profile
  "title.myProfile.text": "My Profile",
  "button.text.changePassword": "Change Password",
  "message.changePassword.sendOtp.success": "OTP Sent on your Email",
  "forgotPassword.button": "Send OTP to Mail",
  "forgotPassword.heading": "Forgot Password?",
  "button.text.updatePassword": "Update Password",
  "button.text.passwordChangedSuccess.success": "Password Changed Successfully",

  // My Profile Page
  "myProfile.text.employeeCode": "Employee Code",
  "myProfile.text.dateOfJoin": "Date of Join",
  "myProfile.text.status": "Status",
  "myProfile.text.phone": "Contact No.",
  "myProfile.text.email": " Office Email",
  "myProfile.text.birthday": "Date of birth",
  "myProfile.text.gender": "Gender",
  "myProfile.text.reportsTo": "Reports to",
  "myProfile.text.designation": "Designation",
  "myProfile.text.department": "Department",
  "myProfile.text.reportable": "Reportable",
  "myProfile.text.team": "Team",

  // leave Balance
  "form.text.leaveBalance.availed": "Availed",
  "form.text.leaveBalance.entitle": "Entitled",
  "form.text.leaveBalance.available": "Available",
  "form.text.leaveBalance.applied": "Applied",
  "leaveBalance.cols.txt.sickLeave": "Sick",
  "leaveBalance.cols.txt.maternityLeave": "Maternity",
  "leaveBalance.cols.txt.paternityLeave": "Paternity",
  "leaveBalance.cols.txt.totalAnnualLeave": "Annual ",
  "leaveBalance.cols.txt.casualLeave": "Casual",
  "leaveBalance.cols.txt.compensatoryLeave": "Compensatory",
  "leaveBalance.cols.txt.unpaidLeave": "Unpaid",
  "leave.balance.error.message": "You do not have enough {leaveType} balance. Current balance is {balance} ",
  "leave.record.error.message": "Something went wrong in DaysOff calculation. Please try later",
  "leave.record.error.daysOffValue": "DaysOff is 0 in this date range due to holiday and weekend. No need to add this leave record.",

  // My Profile Personal Information Card
  "myProfile.personalInformation.title": "Personal Information",
  "myProfile.personalInformation.fatherName": "Father's Name",
  "myProfile.personalInformation.aadhaarNo": "Aadhaar No.",
  "myProfile.personalInformation.tel": "Mobile",
  "myProfile.personalInformation.bloodGroup": "Blood Group",
  "myProfile.personalInformation.maritalStatus": "Marital status",
  "myProfile.personalInformation.spouseName": "Spouse Name",
  "myProfile.personalInformation.employmentType": "Employment Type",
  "myProfile.personalInformation.workLocation": "Work Location",
  "myProfile.emergencyContact.title": "Emergency Contact",
  "myProfile.emergencyContact.name": "Emergency Contact Name",
  "myProfile.emergencyContact.relationship": "Relationship",
  "myProfile.emergencyContact.phone": "Emergency Phone",

  // My Profile Address Information Card
  "myProfile.addressInformation.title": "Address Information",
  "myProfile.addressInformation.currentAddress": "Current Address",
  "myProfile.addressInformation.permanentAddress": "Permanent Address",
  "myProfile.addressInformation.addressLine1": "Address Line 1",
  "myProfile.addressInformation.addressLine2": "Address Line 2",
  "myProfile.addressInformation.city": "City",
  "myProfile.addressInformation.country": "Country",
  "myProfile.addressInformation.pinCode": "PIN Code",
  "myProfile.addressInformation.state": "State",

  // My Profile Bank Information Card
  "myProfile.bankInformation.title": "Bank information",
  "myProfile.bankInformation.accountHolderName": "A/C Holder name",
  "myProfile.bankInformation.bankName": "Bank name",
  "myProfile.bankInformation.bankAccountNo": "Bank account No.",
  "myProfile.bankInformation.IFSC_Code": "IFSC Code",
  "myProfile.bankInformation.panNo": "PAN No",

  // My Documents
  "myDocuments.txt.title": "Add Document",
  "myDocuments.txt.documentCategory": "Document Category",
  "myDocuments.txt.documentName": "Document Name",
  "myDocuments.txt.uploadDocument": "Upload Document",
  "employeeDocumentList.cols.txt.document": "Document",
  "employeeDocumentList.cols.txt.category": "Category",
  "employeeDocumentList.cols.txt.uploadDate": "Upload Date",
  "employeeDocumentList.cols.txt.approved": "Approved Status",
  "icon.add.Document": "Add Document",

  // Company Documents
  "companyDocuments.title": "Policies",
  "companyDocuments.cols.txt.documentName": "Document",
  "companyDocuments.cols.txt.categoryName": "category",
  "companyDocuments.cols.txt.uploadDocument": "Upload Document",

  // My Insurance Details
  "myProfile.myInsurance.title": "Insurance Details",
  "myProfile.myInsurance.employeeCode": "Employee Code",
  "myProfile.myInsurance.employeeName": "Employee Name",
  "myProfile.myInsurance.policyHolderName": "Policy Holder Name",
  "myProfile.myInsurance.policyName": "Policy Name",
  "myProfile.myInsurance.policyNumber": "Policy Number",
  "myProfile.myInsurance.typeOfPolicy": "Type Of Policy",
  "myProfile.myInsurance.sumAssured": "Sum Assured",
  "myProfile.myInsurance.premiumPaymentType": "Premium Payment Type",
  "myProfile.myInsurance.premiumAmount": "Premium Amount",
  "myProfile.myInsurance.policyDuration": "Policy Duration",
  "myProfile.myInsurance.expiryDateOfPolicy": "Expiry Date Of Policy",
  "myProfile.myInsurance.nominee": "Nominee",
  "myProfile.myInsurance.relationWithNominee": "Relation With Nominee",
  "myProfile.myInsurance.insuranceCompany": "Insurance Company",

  // Reporting Manager
  "title.teams": " My Team",
  "title.reporting.manager": "Reporting Manager",

  // Payslip
  "salary.slip.title": "Salary Slip",

  // ---- Datatable
  "searchBox.label": "Search",
  "icon.editIcon": "Edit",
  "icon.deleteIcon": "Delete",
  "cols.txt.action": "Action",
  "cols.txt.employeeFullName": "Employee Name",
  "employeeList.txt.empCode": "Emp.#",
  "viewColumns.tooltip.text": "View Columns",
  "filters.tooltip.text": "Filter List",
  "filters.date.startDate": "Start Date",
  "filters.date.endDate": "End Date",
  "filters.appliedFilter.text": "Filters:",
  "filters.noFilter": "No Filters Available",
  "export.tooltip.text": "Export Data",
  "download.tooltip.text": "Download",

  // Projects
  "projectResourceStatus.assigned.text": "Assigned",
  "projectResourceStatus.released.text": "Released",
  "projectResourceList.cols.txt.designation": "Designation",
  "projectResourceList.cols.txt.role": "Role",
  "projectResourceList.cols.txt.fromDate": "From Date",
  "projectResourceList.cols.txt.toDate": "To Date",
  "projectResourceList.cols.txt.projectResourceStatus": "Status",
  "projectResourceList.cols.txt.allocationPercentage": "Allocation %",
  "projectResourceList.cols.txt.projectName": "Project Name",
  "projectResourceList.cols.txt.projectCode": "Project Code",

  // Assets
  "assetStatus.assigned.text": "Assigned",
  "assetStatus.reserved.text": "Reserved",
  "assetStatus.maintenance.text": "Maintenance",
  "assetStatus.available.text": "Available",
  "asset.cols.txt.assetName": "Asset Name",
  "asset.cols.txt.assetType": "Asset Type",
  "asset.cols.txt.dateOfPurchase": "Date of Purchase",
  "asset.cols.txt.modelNumber": "Model Number",
  "asset.cols.txt.serialNumber": "serial Number",
  "asset.cols.txt.status": "Status",
  "asset.cols.txt.assignDate": "Assign Date",
  "asset.cols.txt.imagePath": "Image",
  "asset.cols.txt.location": "Location",

  // -- payrollDetail list
  "payrollDetailList.cols.txt.totalDeduction": "Total Deduction",
  "payrollDetailList.cols.txt.incomeTotal": "Total Income",
  "payrollDetailList.cols.txt.paidDays": "Paid Days",
  "payrollDetailList.cols.txt.grossMonthly": "Gross Monthly",
  "payrollDetailList.cols.txt.basicMonthly": "Basic Monthly",
  "payrollDetailList.cols.txt.hraMonthly": "HRA Monthly",
  "payrollDetailList.cols.txt.allowanceMonthly": "Allowance Monthly",
  "payrollDetailList.cols.txt.ltaMonthly": "LTA Monthly",
  "payrollDetailList.cols.txt.tds": "TDS",
  "payrollDetailList.cols.txt.flagPf": "Flag PF",
  "payrollDetailList.cols.txt.employeePfMonthly": "EE PF Monthly",
  "payrollDetailList.cols.txt.employerPfMonthly": "ER PF Monthly",
  "payrollDetailList.cols.txt.ptDeduction": "PT Deduction",
  "payrollDetailList.cols.txt.netSalary": "Net Salary",
  "payrollDetailList.cols.txt.paymentMode": "Payment Mode",
  "payrollDetailList.cols.txt.status": "Status",
  "payrollDetailList.cols.txt.modifiedBy": "Modified By",
  "payrollDetailList.cols.txt.modifiedDate": "Modified Date",
  "payrollDetailList.cols.txt.flagErPf": "Flag ER Pf",
  "payrollDetailList.cols.txt.employeePfPercentage": "EE PF Percentage",
  "payrollDetailList.cols.txt.employerPfPercentage": "ER PR Percentage",
  "payrollDetailList.cols.txt.employeeEsiMonthly": "EE ESI Monthly",
  "payrollDetailList.cols.txt.flagEsi": "Flag ESI",
  "payrollDetailList.cols.txt.employeeEsiPercentage": "EE ESI Percentage",
  "payrollDetailList.cols.txt.flagPt": "Flag PT",
  "payrollDetailList.cols.txt.employerEsiMonthly": "ER ESI Monthly",
  "payrollDetailList.cols.txt.employerEsiPercentage": "ER ESI Percentage",
  "payrollDetailList.cols.txt.flagErEsi": "Flag ER ESI",
  "payrollDetailList.cols.txt.basicPercentage": "Basic Percentage",
  "payrollDetailList.cols.txt.hraPercentage": "HRA Percentage",
  "payrollDetailList.cols.txt.ltaPercentage": "LTA Percentage",

  // ---- payslip list
  "payslip.cols.txt.accountNumber": "A/C No.",
  "payslip.cols.txt.bankName": "Bank",
  "payslip.cols.txt.ifscCode": "IFSC",

  //---Employee List
  "employeeList.cols.txt.gender": "Gender",
  "employeeList.cols.txt.officeEmail": "Official Email",
  "employeeList.cols.txt.officeContact": "Official Contact No.",
  "employeeList.cols.txt.contact": "Contact",
  "employeeList.cols.txt.employeeStatus": "Status",
  "employeeList.txt.reportingManager": "Reporting Manager",
  "employeeStatus.active.text": "Active",
  "employeeStatus.inactive.text": "Inactive",
  "employeeStatus.released.text": "Released",
  "employeeStatus.retired.text": "Retired",
  "employeeList.txt.dateOfJoining": "Date Of Joining",
  "employeeList.txt.dob": "Date Of Birth",
  "employeeList.txt.department": "Department",
  "employeeList.txt.bloodGroup": "Blood Group",
  "employeeList.txt.pan": "PAN",
  "employeeList.txt.aadhaarNumber": "AadhaarNumber",
  "employeeList.txt.designation": "Designation",
  "employeeList.txt.personalEmail": "PersonalEmail",
  "icon.add.employee": "Add Employee",

  "payrollList.cols.txt.month": "Month",
  "payrollList.cols.txt.payableDays": "Payable Days",
  "payrollList.cols.txt.year": "Year",

  // Filter PopUp
  "filters.close.popup.button": "Close",

  // Axios Config
  "error.message.internalServer": "Sorry, Something went wrong!",
  "error.message.sessionExpired": "Session Expired Please Login",

  //Error Messages
  "to.date.already.exists.in.other.leave": "To date already exists in other leave",
  "from.date.already.exists.in.other.leave": "From date already exists in other leave",
  "employeeDocument.entity.already.exists": "Employee Document Already Exists",
  "leave.can.not.update.payroll.created": "Leave can not update payroll created",

  // Success Message
  "records.saved.success": "Records saved successfully",
  "records.update.success": "Records updated successfully",
  "leave.grant.saved.success": "Leave Grant saved successfully",
  "leave.grant.updated": "Leave Grant updated successfully",
  "leave-grant-applied": "Grant Applied",

  // Delete Messages
  "employeeDocument.entity.delete.success": "Employee Document deleted successfully",

  //employee education
  "emp.edu.tittle": "Education Information",
  "emp.edu.stream": "Stream",
  "emp.edu.qualificationType": "Qualification Type",
  "emp.edu.courseName": "Course Name",
  "emp.edu.courseType": "Course Type",
  "emp.edu.courseStartDate": "Course Start Date",
  "emp.edu.courseEndDate": "Course End Date",
  "emp.edu.grade": "Grade",
  "emp.edu.collegeName": "College/School",
  "emp.edu.universityName": "University/Board",
  "emp.edu.description": "Description",
  "form.text.empDocument.uploadDocument": "Upload Document",
  "allFiles.menu.txt": "All Files",
};
