/**
 * Rct Section Loader
 */
import React from "react";
import Spinner from "./Spinner";

const RctSectionLoader = ({ size }) => (
  <div className="d-flex justify-content-center loader-overlay">
    <Spinner />
    {/* <CircularProgress size={size} /> */}
  </div>
);

export default RctSectionLoader;
