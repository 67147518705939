import { all } from "redux-saga/effects";

// sagas
// import employeeSaga from "./EmployeeSaga";
import utilityControllerSaga from "./utilityControllerSaga";
export default function* rootSaga() {
  yield all([
    // employeeSaga(),
    utilityControllerSaga(),
  ]);
}
