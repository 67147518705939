import PropTypes from "prop-types";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import IntlMessages from "../../../util/IntlMessages";

export const FormFooter = ({ loading, setOpenDialog }) => {
  FormFooter.propTypes = {
    loading: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
  };

  return (
    <Row className="FooterForm">
      <Col /* style={{ padding: "0px 0px 0px 110px" }} */>
        <Button
          variant="contained"
          color="outline-secondary"
          onClick={() => setOpenDialog(false)}
          className="buttonSpacing">
          <IntlMessages id={`button.text.cancel`} />
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className="buttonSpacing"
          disabled={loading}>
          {loading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          {loading && <IntlMessages id={`button.text.save`} />}
          {!loading && <IntlMessages id={`button.text.save`} />}
        </Button>
      </Col>
    </Row>
  );
};
