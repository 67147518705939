import CachedIcon from "@mui/icons-material/Cached";
import { Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import PropTypes from "prop-types";
import * as React from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import IntlMessages from "../../../../util/IntlMessages";

const ResponsiveDateRangePicker = ({ item, handleDateFilterChange, clearDate }) => {
  ResponsiveDateRangePicker.propTypes = {
    item: PropTypes.object.isRequired,
    handleDateFilterChange: PropTypes.func.isRequired,
    clearDate: PropTypes.func,
  };
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  React.useEffect(() => {
    if (startDate && endDate) {
      handleDateFilterChange(item, startDate.toString(), endDate.toString());
    }
  }, [startDate, endDate]);

  React.useEffect(() => {
    handleClear();
  }, [clearDate]);

  const handleClear = () => {
    if (startDate && endDate) {
      handleDateFilterChange(item, startDate.toString(), endDate.toString());
    } else {
      handleDateFilterChange(item);
    }
    setStartDate(item.startDateInitial ? item.startDateInitial : null);
    setEndDate(item.endDateInitial ? item.endDateInitial : null);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Row>
        <Col
          xl={4}
          md={4}
          sm={4}
          xs={12}
          className="text-right pb-2">
          <DatePicker
            // disableFuture
            label={<IntlMessages id={"filters.date.startDate"} />}
            openTo="day"
            views={["year", "month", "day"]}
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="DD/MM/YYYY"
            className="filterDate"
          />
        </Col>
        <Col
          xl={4}
          md={4}
          sm={4}
          xs={12}
          className="pb-2">
          <DatePicker
            label={<IntlMessages id={"filters.date.endDate"} />}
            openTo="day"
            views={["year", "month", "day"]}
            value={endDate}
            onChange={(newValue) => {
              if (newValue) {
                const formattedDate = moment(newValue).format("DD/MM/YYYY");
                if (formattedDate.length >= 10 && formattedDate != "Invalid date") {
                  if (!startDate || moment(newValue).isSameOrAfter(startDate, "day")) {
                    setEndDate(newValue);
                  } else {
                    toast.error("End date must be greater than or equal to start date");
                  }
                }
              }
            }}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="DD/MM/YYYY"
            className="filterDate"
          />
        </Col>
        <Col
          xl={2}
          md={2}
          sm={2}
          xs={12}
          className="pb-2">
          <Tooltip
            title="Clear Dates"
            placement="right-start">
            <CachedIcon
              className="mt-1"
              fontSize="large"
              onClick={() => handleClear()}
            />
          </Tooltip>
        </Col>
      </Row>
    </LocalizationProvider>
  );
};
export default ResponsiveDateRangePicker;
