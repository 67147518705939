import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosInstance } from "../../config/AxiosConfig";
import {
  GET_ALLENUMS_REQUEST,
  GET_ALLENUMS_SUCSESS,
  GET_BIRTHDAYS_FAILURE,
  GET_BIRTHDAYS_REQUEST,
  GET_BIRTHDAYS_SUCCESS,
  GET_HOLIDAYS_FAILURE,
  GET_HOLIDAYS_REQUEST,
  GET_HOLIDAYS_SUCCESS,
  GET_LINKS_FAILURE,
  GET_LINKS_REQUEST,
  GET_LINKS_SUCCESS,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
} from "../actions/type";
import { companyEventsApi, getAllBirthdays, getAllEnums, getAllHolidays, importantLinksApi } from "../ApiLists";

const getAllEnumsApi = async () => await AxiosInstance.get(`${getAllEnums}`);
const getAllBirthDaysApi = async () => await AxiosInstance.get(`${getAllBirthdays}`);
const getAllHoliDaysApi = async () => await AxiosInstance.get(`${getAllHolidays}`);
const getAllLinksApi = async () => await AxiosInstance.get(`${importantLinksApi}`);
const getEventsApi = async () => await AxiosInstance.get(`${companyEventsApi}`);

// Get ALL E-NUMS
function* getAllEnumsSaga() {
  try {
    const response = yield call(getAllEnumsApi);
    yield put({
      type: GET_ALLENUMS_SUCSESS,
      payload: response,
    });
  } catch (e) {
    if (e.toString().includes("404")) {
      yield put({
        type: GET_ALLENUMS_SUCSESS,
        payload: { data: "not found", status: 404 },
      });
    } else {
      yield put({ type: GET_ALLENUMS_FAILURE, payload: e });
    }
  }
}
// Get BirthDay's
function* getAllBirthdaysSaga() {
  try {
    const response = yield call(getAllBirthDaysApi);

    yield put({
      type: GET_BIRTHDAYS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    if (e.toString().includes("404")) {
      yield put({
        type: GET_BIRTHDAYS_SUCCESS,
        payload: { data: "not found", status: 404 },
      });
    } else {
      yield put({ type: GET_BIRTHDAYS_FAILURE, payload: e });
    }
  }
}

// Get Holiday's
function* getAllHolidaysSaga() {
  try {
    const response = yield call(getAllHoliDaysApi);
    yield put({
      type: GET_HOLIDAYS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    if (e.toString().includes("404")) {
      yield put({
        type: GET_HOLIDAYS_SUCCESS,
        payload: { data: "not found", status: 404 },
      });
    } else {
      yield put({ type: GET_HOLIDAYS_FAILURE, payload: e });
    }
  }
}

// Get Link's
function* getAllLinksSaga() {
  try {
    const response = yield call(getAllLinksApi);
    yield put({
      type: GET_LINKS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    if (e.toString().includes("404")) {
      yield put({
        type: GET_LINKS_SUCCESS,
        payload: { data: "not found", status: 404 },
      });
    } else {
      yield put({ type: GET_LINKS_FAILURE, payload: e });
    }
  }
}

// Get Event's
function* getEventsSaga() {
  try {
    const response = yield call(getEventsApi);
    yield put({
      type: GET_EVENTS_SUCCESS,
      payload: response,
    });
  } catch (e) {
    if (e.toString().includes("404")) {
      yield put({
        type: GET_EVENTS_SUCCESS,
        payload: { data: "not found", status: 404 },
      });
    } else {
      yield put({ type: GET_EVENTS_FAILURE, payload: e });
    }
  }
}

export default function* watcherSaga() {
  yield takeLatest(GET_ALLENUMS_REQUEST, getAllEnumsSaga);
  yield takeLatest(GET_BIRTHDAYS_REQUEST, getAllBirthdaysSaga);
  yield takeLatest(GET_HOLIDAYS_REQUEST, getAllHolidaysSaga);
  yield takeLatest(GET_LINKS_REQUEST, getAllLinksSaga);
  yield takeLatest(GET_EVENTS_REQUEST, getEventsSaga);
}
