import { CubeProvider } from "@cubejs-client/react";
import React, { useEffect, useState } from "react";
import RctSectionLoader from "../components/shared/spinner/RctSectionLoader";
import { getCubeJsApi } from "./cubeApi";

/**
 * cubejsApi will be available to all components wrapped with this.
 * Wrapped components can call useCubeQuery without passeing cubejsApi
 *
 * @param {*} { children }
 */
function CubeJsApiWrapper({ children }) {
  const [cubeJsApi, setCubeJsApi] = useState(null);

  useEffect(() => {
    (async () => {
      setCubeJsApi(await getCubeJsApi());
    })();
  }, []);

  return cubeJsApi ? <CubeProvider cubejsApi={cubeJsApi}>{children}</CubeProvider> : <RctSectionLoader />;
}

export default CubeJsApiWrapper;
