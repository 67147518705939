import {
  GET_ALLENUMS_REQUEST,
  GET_ALLENUMS_SUCSESS,
  GET_BIRTHDAYS_FAILURE,
  GET_BIRTHDAYS_REQUEST,
  GET_BIRTHDAYS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_EVENTS_REQUEST,
  GET_EVENTS_SUCCESS,
  GET_HOLIDAYS_FAILURE,
  GET_HOLIDAYS_REQUEST,
  GET_HOLIDAYS_SUCCESS,
  GET_LINKS_FAILURE,
  GET_LINKS_REQUEST,
  GET_LINKS_SUCCESS,
} from "../actions/type";

const INIT_STATE = {
  utilAllBirthDays: undefined,
  utilHolidays: undefined,
  utilLinks: undefined,
  utilEvents: undefined,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ALLENUMS_REQUEST:
      return { ...state, utilAllEnums: {} };
    case GET_ALLENUMS_SUCSESS:
      return { ...state, utilAllEnums: action.payload };
    case GET_BIRTHDAYS_REQUEST:
      return { ...state, utilAllBirthDays: {} };
    case GET_BIRTHDAYS_SUCCESS:
      return { ...state, utilAllBirthDays: action.payload };
    case GET_BIRTHDAYS_FAILURE:
      return { ...state, utilAllBirthDays: {} };
    case GET_HOLIDAYS_REQUEST:
      return { ...state, utilHolidays: {} };
    case GET_HOLIDAYS_SUCCESS:
      return { ...state, utilHolidays: action.payload };
    case GET_HOLIDAYS_FAILURE:
      return { ...state, utilHolidays: {} };
    case GET_LINKS_REQUEST:
      return { ...state, utilLinks: {} };
    case GET_LINKS_SUCCESS:
      return { ...state, utilLinks: action.payload };
    case GET_LINKS_FAILURE:
      return { ...state, utilLinks: {} };
    case GET_EVENTS_REQUEST:
      return { ...state, utilEvents: {} };
    case GET_EVENTS_SUCCESS:
      return { ...state, utilEvents: action.payload };
    case GET_EVENTS_FAILURE:
      return { ...state, utilEvents: {} };
    default:
      return { ...state };
  }
};
