import { CubeContext } from "@cubejs-client/react";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, FormGroup, Row } from "reactstrap";
import { downloadEmployeePhoto } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";
import ProfilePic from "../profilePic/ProfilePic";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import RctSectionLoader from "../shared/spinner/RctSectionLoader";
import ReportingManagerDetail from "./ReportingManagerDetail";
import { getProfile } from "../authentication/HandleCookies";

function MyProfileNew() {
  const { cubejsApi } = useContext(CubeContext);
  const [openDialog, setOpenDialog] = useState(false);

  const URLS = {
    download: `${downloadEmployeePhoto}`,
  };
  const employeeSchema = "EmployeeDetail";
  const workLocationSchema = "WorkLocation";
  const bankDetailSchema = "BankDetail";
  const addressSchema = "Address";
  const reportingManagerSchema = "ReportingManager";
  const accessToken = getProfile();
  const profile = JSON.parse(getProfile());
  const employeeID = profile.employee.employeeId;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  const redirectToLogin = () => {
    window.location.href = "/login";
  };

  const EMPLOYEE_DETAIL_QUERY = {
    limit: 1,
    dimensions: [
      `${employeeSchema}.employeeId`,
      `${employeeSchema}.officeEmail`,
      `${employeeSchema}.title`,
      `${employeeSchema}.personalEmail`,
      `${employeeSchema}.empCode`,
      `${employeeSchema}.fullName`,
      `${employeeSchema}.firstName`,
      `${employeeSchema}.lastName`,
      `${employeeSchema}.gender`,
      `${employeeSchema}.designation`,
      `${employeeSchema}.aadhaarNumber`,
      `${employeeSchema}.pan`,
      `${employeeSchema}.contactNumber`,
      `${employeeSchema}.emgContactName`,
      `${employeeSchema}.emgContactNumber`,
      `${employeeSchema}.emgContactRelation`,
      `${employeeSchema}.employmentType`,
      `${employeeSchema}.reportingManagerId`,
      `${employeeSchema}.employeeStatus`,
      `${employeeSchema}.bloodGroup`,
      `${employeeSchema}.maritalStatus`,
      `${employeeSchema}.spouseName`,
      `${employeeSchema}.fatherName`,
      `${employeeSchema}.department`,
      `${workLocationSchema}.name`,
      `${employeeSchema}.modifiedBy`,
      `${employeeSchema}.modifiedDate`,
      `${employeeSchema}.reportable`,
      `${employeeSchema}.isUserCreated`,
      `${employeeSchema}.uan`,
      `${employeeSchema}.dob`,
      `${employeeSchema}.dateOfJoining`,
      `${employeeSchema}.officeContactNumber`,

      `${addressSchema}.addressId`,
      `${addressSchema}.sameAddressFlag`,
      `${addressSchema}.crCity`,
      `${addressSchema}.crCountry`,
      `${addressSchema}.crPinCode`,
      `${addressSchema}.crState`,
      `${addressSchema}.prAddressLine1`,
      `${addressSchema}.prAddressLine2`,
      `${addressSchema}.prCity`,
      `${addressSchema}.crAddressLine1`,
      `${addressSchema}.crAddressLine2`,
      `${addressSchema}.prCountry`,
      `${addressSchema}.prState`,
      `${addressSchema}.prPinCode`,
      `${addressSchema}.modifiedBy`,
      `${addressSchema}.modifiedDate`,

      `${bankDetailSchema}.bankDetailId`,
      `${bankDetailSchema}.branchName`,
      `${bankDetailSchema}.ifscCode`,
      `${bankDetailSchema}.accountNumber`,
      `${bankDetailSchema}.bankName`,
      `${bankDetailSchema}.accountHolderName`,
      `${bankDetailSchema}.modifiedBy`,
      `${bankDetailSchema}.modifiedDate`,

      `${reportingManagerSchema}.employeeId`,
      `${reportingManagerSchema}.fullName`,
      `${reportingManagerSchema}.firstName`,
      `${reportingManagerSchema}.lastName`,
      `${reportingManagerSchema}.gender`,
      `${reportingManagerSchema}.empCode`,
      `${reportingManagerSchema}.contactNumber`,
      `${reportingManagerSchema}.officeEmail`,
    ],
    filters: [
      {
        member: `${employeeSchema}.employeeId`,
        operator: "equals",
        values: [`${employeeID}`],
      },
    ],
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "New":
        return "#0D6EFD";
      case "Active":
        return "#07BC0C";
      case "Inactive":
        return "#7A838B";
      case "Released":
        return "#9C45D6";
      case "Retired":
        return "#FFC300";
      case "Terminated":
        return "#EE4B2B";
      default:
        return "#FF9900";
    }
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "New":
        return "#8BB5F5";
      case "Active":
        return "#B7F8E4";
      case "Inactive":
        return "#C5CDD3";
      case "Released":
        return "#DABEEC";
      case "Retired":
        return "#716B57";
      case "Terminated":
        return "#E6A496";
      default:
        return "#554835";
    }
  };
  const onMyProfileClick = () => {
    setOpenDialog(true);
  };
  const getData = async () => {
    setIsLoading(true);
    await cubejsApi
      .load(EMPLOYEE_DETAIL_QUERY)
      .then((resultSet) => {
        const response = resultSet.tablePivot();
        if (response?.length > 0) {
          setData(response);
        }
      })
      .catch((error) => {
        console.error("----> Errorrr ", error);
      });
    setIsLoading(false);
  };
  if (employeeID && accessToken) {
    useEffect(() => {
      getData();
    }, []);
    if (isLoading) {
      return <RctSectionLoader />;
    }
    if (!isLoading && !data) {
      return `Something Went Wrong. Error Getting Employee Detail.`;
    }
    let empStatus = null;

    if (data && data.length > 0) {
      const employeeDetail = data[0];
      empStatus = employeeDetail[`${employeeSchema}.employeeStatus`];
    }
    if (data && data.length > 0) {
      const employeeDetail = data[0];

      const firstName = employeeDetail?.[`${reportingManagerSchema}.firstName`];
      const lastName = employeeDetail?.[`${reportingManagerSchema}.lastName`];

      const fullName = firstName && lastName ? `${firstName} ${lastName}` : "-";

      const isReportable = employeeDetail?.[`${employeeSchema}.reportable`];

      const reportableStatus = isReportable !== undefined ? (isReportable ? "Yes" : "No") : "-";

      return (
        <>
          <TitleHeader
            shouldShowArrow={true}
            title="HRMS-employee"
            pageTitle={"title.myProfile.text"}
          />
          <div className="container-fluid myProfileSection">
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="profile-view"></div>
                </div>

                <Row>
                  <Col className="col-12 col-sm-12 col-md-6">
                    <Row>
                      <div className="image-position center-content">
                        <div className="EmpImageArea center-content">
                          <ProfilePic
                            Id={employeeDetail[`${employeeSchema}.employeeId`]}
                            withId={true}
                            shouldEdit={false}
                            urls={URLS}
                            reload={true}
                            forDefaultImg={true}
                            clickable={true}
                            Aspect={{ value: 1, text: "1 / 1" }}
                          />
                        </div>
                      </div>
                      <Row>
                        <Col className="col-sm-12 col-md-12 col-md-12 status-divcenter">{employeeDetail[`${employeeSchema}.firstName`] + " " + employeeDetail[`${employeeSchema}.lastName`]}</Col>
                      </Row>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        <Link
                          className="custom-link"
                          to={{
                            pathname: "/team-list",
                            state: employeeDetail[`${employeeSchema}.employeeId`],
                          }}>
                          Team
                        </Link>

                        <span style={{ marginLeft: "8px" }}>{employeeDetail && employeeDetail[`${employeeSchema}.empCode`] ? employeeDetail[`${employeeSchema}.empCode`] : "-"}</span>
                        <div className="status-divcenter mb-2 ">
                          <span
                            className="status-box"
                            style={{ marginBlock: "auto", color: getStatusColor(empStatus), backgroundColor: getStatusBackgroundColor(empStatus), marginLeft: "8px" }}>
                            {empStatus}
                          </span>
                        </div>
                      </div>
                    </Row>
                  </Col>
                  <Col className="col-12 col-sm-6 col-md-3">
                    <FormGroup>
                      <div className="title">
                        <IntlMessages id="myProfile.text.reportsTo" />
                      </div>

                      <Link
                        className="text mt-3"
                        onClick={onMyProfileClick}>
                        <div className="textlink">{fullName}</div>
                      </Link>
                    </FormGroup>

                    <FormGroup>
                      <div className="title">
                        <IntlMessages id="myProfile.text.reportable" />
                      </div>
                      <Row>
                        <div className="disable-div">{reportableStatus}</div>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col className="col-12 col-sm-6 col-md-3">
                    <FormGroup>
                      <div className="detail-info-text">
                        <IntlMessages
                          style={{ color: "black" }}
                          id="myProfile.text.designation"
                        />
                      </div>
                      <br />
                      <Row>
                        <Col className="col-12 col-sm-12 col-md-12">
                          <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.designation`] ? employeeDetail[`${employeeSchema}.designation`] : "-"}</div>
                        </Col>
                      </Row>
                    </FormGroup>
                    <Row>
                      <FormGroup>
                        <div className="title">
                          <IntlMessages id="myProfile.text.phone" />{" "}
                        </div>

                        <Row>
                          <Col className="col-12 col-sm-12 col-md-12">
                            <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.contactNumber`] ? employeeDetail[`${employeeSchema}.contactNumber`] : "-"}</div>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="tab-content">
              <div
                id="emp_profile"
                className="pro-overview tab-pane fade active show">
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">
                          <IntlMessages id="myProfile.personalInformation.title" />
                          <a
                            href="#section"
                            className="edit-icon"
                            data-bs-toggle="modal"
                            data-bs-target="#personal_info_modal">
                            <i className="fa fa-pencil"></i>
                          </a>
                        </h3>
                        <div className="personal-info">
                          <Row>
                            <Col className="col-12 col-sm-6 col-md-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.personalInformation.fatherName" />
                                </div>

                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-6 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.fatherName`] ? employeeDetail[`${employeeSchema}.fatherName`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.text.birthday" />
                                </div>

                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">
                                      {employeeDetail && employeeDetail[`${employeeSchema}.dob`] ? new Date(employeeDetail[`${employeeSchema}.dob`]).toLocaleDateString("en-GB") : "-"}
                                    </div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.text.gender" />
                                </div>

                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.gender`] ? employeeDetail[`${employeeSchema}.gender`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.text.dateOfJoin" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">
                                      <div className="disable-div">
                                        {employeeDetail && employeeDetail[`${employeeSchema}.dateOfJoining`]
                                          ? new Date(employeeDetail[`${employeeSchema}.dateOfJoining`]).toLocaleDateString("en-GB")
                                          : "-"}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.personalInformation.maritalStatus" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.maritalStatus`] ? employeeDetail[`${employeeSchema}.maritalStatus`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.personalInformation.spouseName" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.maritalStatus`] ? employeeDetail[`${employeeSchema}.maritalStatus`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.personalInformation.employmentType" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.employmentType`] ? employeeDetail[`${employeeSchema}.employmentType`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.personalInformation.workLocation" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${workLocationSchema}.name`] ? employeeDetail[`${workLocationSchema}.name`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <Row>
                          <Col className="col-12 col-sm-6 ">
                            <FormGroup>
                              <div className="title">
                                <IntlMessages id="myProfile.text.email" />
                              </div>
                              <Row>
                                <div className="text">
                                  <div className="text">{employeeDetail && employeeDetail[`${employeeSchema}.officeEmail`] ? employeeDetail[`${employeeSchema}.officeEmail`] : "-"}</div>
                                </div>
                              </Row>
                            </FormGroup>
                          </Col>

                          <Col className="col-12 col-sm-6 ">
                            <FormGroup>
                              <div className="detail-info-text">
                                <IntlMessages id="myProfile.text.department" />
                              </div>
                              <br />
                              <Row>
                                <Col className="col-12 col-sm-12 col-md-12">
                                  <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.department`] ? employeeDetail[`${employeeSchema}.department`] : "-"}</div>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-12 col-sm-6 ">
                            <FormGroup>
                              <div className="detail-info-text">
                                <IntlMessages id="myProfile.personalInformation.aadhaarNo" />
                              </div>
                              <br />
                              <Row>
                                <Col className="col-12 col-sm-12 col-md-12">
                                  <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.aadhaarNumber`] ? employeeDetail[`${employeeSchema}.aadhaarNumber`] : "-"}</div>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col className="col-12 col-sm-6 ">
                            <FormGroup>
                              <div className="detail-info-text">
                                <IntlMessages id="myProfile.bankInformation.panNo" />
                              </div>
                              <br />
                              <Row>
                                <Col className="col-12 col-sm-12 col-md-12">
                                  <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.pan`] ? employeeDetail[`${employeeSchema}.pan`] : "-"}</div>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-12 col-sm-6 ">
                            <FormGroup>
                              <div className="detail-info-text">
                                <IntlMessages id="employeeList.cols.txt.officeContact" />
                              </div>
                              <br />
                              <Row>
                                <Col className="col-12 col-sm-12 col-md-12">
                                  <div className="disable-div">
                                    {employeeDetail && employeeDetail[`${employeeSchema}.officeContactNumber`] ? employeeDetail[`${employeeSchema}.officeContactNumber`] : "-"}
                                  </div>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                          <Col className="col-12 col-sm-6 ">
                            <FormGroup>
                              <div className="detail-info-text">
                                <IntlMessages id="employeeList.txt.personalEmail" />
                              </div>
                              <br />
                              <Row>
                                <Col className="col-12 col-sm-12 col-md-12">
                                  <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.personalEmail`] ? employeeDetail[`${employeeSchema}.personalEmail`] : "-"}</div>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">
                          <IntlMessages id="myProfile.addressInformation.currentAddress" />
                          <a
                            href="#section"
                            className="edit-icon"
                            data-bs-toggle="modal"
                            data-bs-target="#personal_info_modal">
                            <i className="fa fa-pencil"></i>
                          </a>
                        </h3>

                        <div className="personal-info">
                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.addressLine1" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.crAddressLine1`] ? employeeDetail[`${addressSchema}.crAddressLine1`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.addressLine2" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.crAddressLine2`] ? employeeDetail[`${addressSchema}.crAddressLine2`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.city" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.crCity`] ? employeeDetail[`${addressSchema}.crCity`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.state" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.crState`] ? employeeDetail[`${addressSchema}.crState`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.country" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.crCountry`] ? employeeDetail[`${addressSchema}.crCountry`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.pinCode" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.crPinCode`] ? employeeDetail[`${addressSchema}.crPinCode`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <hr />

                        <h3 className="card-title">
                          <IntlMessages id="myProfile.addressInformation.permanentAddress" />
                          <a
                            href="#section"
                            className="edit-icon"
                            data-bs-toggle="modal"
                            data-bs-target="#personal_info_modal">
                            <i className="fa fa-pencil"></i>
                          </a>
                        </h3>
                        <div className="personal-info">
                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.addressLine1" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.prAddressLine1`] ? employeeDetail[`${addressSchema}.prAddressLine1`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.addressLine2" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.prAddressLine2`] ? employeeDetail[`${addressSchema}.prAddressLine2`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.city" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.prCity`] ? employeeDetail[`${addressSchema}.prCity`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.state" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.prState`] ? employeeDetail[`${addressSchema}.prState`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.country" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.prCountry`] ? employeeDetail[`${addressSchema}.prCountry`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.addressInformation.pinCode" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${addressSchema}.prPinCode`] ? employeeDetail[`${addressSchema}.prPinCode`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">
                          <IntlMessages id="myProfile.bankInformation.title" />
                        </h3>
                        <div className="personal-info">
                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.bankInformation.accountHolderName" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">
                                      {employeeDetail && (
                                        <>
                                          {employeeDetail[`${bankDetailSchema}.accountHolderName`] ? employeeDetail[`${bankDetailSchema}.accountHolderName`] : "-"}{" "}
                                          {employeeDetail[`${addressSchema}.prPinCode`] ? employeeDetail[`${addressSchema}.prPinCode`] : "-"}
                                        </>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.bankInformation.bankAccountNo" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail[`${bankDetailSchema}.accountNumber`]}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.bankInformation.bankName" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${bankDetailSchema}.bankName`] ? employeeDetail[`${bankDetailSchema}.bankName`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.bankInformation.IFSC_Code" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${bankDetailSchema}.ifscCode`] ? employeeDetail[`${bankDetailSchema}.ifscCode`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex">
                    <div className="card profile-box flex-fill">
                      <div className="card-body">
                        <h3 className="card-title">
                          <IntlMessages id="myProfile.emergencyContact.title" />
                          <a
                            href="#section"
                            className="edit-icon"
                            data-bs-toggle="modal"
                            data-bs-target="#emergency_contact_modal">
                            <i className="fa fa-pencil"></i>
                          </a>
                        </h3>
                        <div className="personal-info">
                          <Row>
                            <Col className="col-12 col-sm-6">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.emergencyContact.name" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.emgContactName`] ? employeeDetail[`${employeeSchema}.emgContactName`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.emergencyContact.relationship" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">
                                      {employeeDetail && employeeDetail[`${employeeSchema}.emgContactRelation`] ? employeeDetail[`${employeeSchema}.emgContactRelation`] : "-"}
                                    </div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.emergencyContact.phone" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">
                                      {employeeDetail && employeeDetail[`${employeeSchema}.emgContactNumber`] ? employeeDetail[`${employeeSchema}.emgContactNumber`] : "-"}
                                    </div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                            <Col className="col-12 col-sm-6 ">
                              <FormGroup>
                                <div className="detail-info-text">
                                  <IntlMessages id="myProfile.personalInformation.bloodGroup" />
                                </div>
                                <br />
                                <Row>
                                  <Col className="col-12 col-sm-12 col-md-12">
                                    <div className="disable-div">{employeeDetail && employeeDetail[`${employeeSchema}.bloodGroup`] ? employeeDetail[`${employeeSchema}.bloodGroup`] : "-"}</div>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {openDialog && (
            <ReportingManagerDetail
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              rowData={employeeDetail}
            />
          )}
        </>
      );
    }
  } else {
    redirectToLogin();
  }
}

export default MyProfileNew;
