// Emp Address Controller
export const portalEmployeeaddressApi = `address`;
export const portalEmployeeBankDetail = `bank-detail`;
export const portalEmployeeAssets = `asset`;
export const getAllBirthdays = `birthday`;
export const getAllHolidays = `holidays`;
export const getInsuranceDetail = `insurance`;
export const getLeaveRecordList = `leaves`;
export const updateLeaveRecord = `leaves`;
export const getMyProfileApi = `details`;
export const getEmployeeDetailsApi = `details`;
export const getProjectByEmpId = `project-resource`;
export const getAllPayslipsApi = `payslips`;
export const downloadPaySlipApi = `download-payslip/`;
export const paySlipPdfApi = `download-payslip/`;
export const downloadEmployeeImageApi = `downloadImage/`;
export const getAllEnums = "util/getAllEnums";
export const empPunchStatus = "punch-data";
export const empPunchTime = "punch-time";

// Documents
export const documentApi = `document`;
const profileApi = "profile-photo";
const companyLogo = "company-logo";
const assetApi = "asset-icon";
export const downloadEmployeePhoto = `${documentApi}/${profileApi}`;
export const downloadPhoto = `${documentApi}/${profileApi}`;
export const downloadAssetFile = `${documentApi}/${assetApi}`;
export const getCompanyLogo = `${documentApi}/${companyLogo}/download`;

export const downloadDocumentApi = `${documentApi}/downloadDocument/`;
export const uploadDocumentApi = `${documentApi}/createDocument`;
export const getDocumentListApi = `${documentApi}/documents`;
export const deleteDocumentApi = `${documentApi}/delete`;
export const listCompanyDocumentApi = `${documentApi}/company-documents`;
export const downloadCompanyDocumentApi = `${documentApi}/download-company-document`;

// Documents Category
export const documentCategoryApi = `util/listDocumentCategory`;

// Company Events
export const companyEventsApi = `util/listAllEvents`;

//  Public Api Controller
const publicApis = "public";
export const loginCompany = `${publicApis}/login`;
export const logoutApi = `${publicApis}/logout`;

// Important Links
export const importantLinksApi = `links`;

// Company Detail
export const companyDetailApi = `${publicApis}/company`;

// Employee leave grant
export const leaveGrantApi = `leave-grants`;
export const applyLeaveAgainstLeaveGrantApi = `leave-grants/apply-leave`;

// Forgot Password
export const forgotPasswordApi = `${publicApis}/user/forgotPassword`;
export const updatePasswordApi = `${publicApis}/user/updatePassword`;
export const changePasswordApi = `${publicApis}/user/changePassword`;

// Reporting Manager
export const reportingManagerApi = `reportingManager`;
export const filterEmployeeForReportableEmployeeApi = `filterEmployeeForReportableEmployee`;

// Emp Image Controller
export const downloadAssetImageApi = `downloadAsset/`;

// Work Days Setting Api's
export const workDaySettingApi = `util/workday-settings`;

// eduactionApi
export const employeeEducation = `employee-education`;
export const educationDocDownload = `employee-education/download`;

//document download api
export const documentDetailDownload = `document/downloadDocument`;
export const documentDetailUpload = `document/uploadDocument`;
