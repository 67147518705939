export const GET_ALLENUMS_REQUEST = "GET_GETALLENUMS_REQUEST";
export const GET_ALLENUMS_SUCSESS = "GET_GETALLENUMS_SUCSESS";
export const GET_BIRTHDAYS_REQUEST = "GET_BIRTHDAYS_REQUEST";
export const GET_BIRTHDAYS_SUCCESS = "GET_BIRTHDAYS_SUCCESS";
export const GET_BIRTHDAYS_FAILURE = "GET_BIRTHDAYS_FAILURE";
export const GET_HOLIDAYS_REQUEST = "GET_HOLIDAYS_REQUEST";
export const GET_HOLIDAYS_SUCCESS = "GET_HOLIDAYS_SUCCESS";
export const GET_HOLIDAYS_FAILURE = "GET_HOLIDAYS_FAILURE";
export const UPDATE_DASHBOARD_FLAG = "UPDATE_DASHBOARD_FLAG";
export const GET_LINKS_REQUEST = "GET_LINKS_REQUEST";
export const GET_LINKS_SUCCESS = "GET_LINKS_SUCCESS";
export const GET_LINKS_FAILURE = "GET_LINKS_FAILURE";
export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";
