import React from "react";
import FileEditButton from "../../FileEditButton";
import PropTypes from "prop-types";

const EditEntityWrapper = ({ popupComponent: Component, editIconColour, disabled, disableButton, setOpenDialog }) => {
  EditEntityWrapper.propTypes = {
    popupComponent: PropTypes.elementType,
    editIconColour: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    disableButton: PropTypes.func.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
  };
  return (
    <span className="tableActionButton">
      <FileEditButton
        setOpenDialog={setOpenDialog}
        editIconColour={editIconColour}
        disabled={disabled}
        disableButton={disableButton}
      />
    </span>
  );
};
export default EditEntityWrapper;
