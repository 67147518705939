import { Grid, IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Form, Input } from "reactstrap";
import { AxiosInstance } from "../../config/AxiosConfig";
import { documentDetailUpload } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";

const AddDocumentDetail = ({ reloadTable, iconTooltipId, entityId, documentType, disable }) => {
  AddDocumentDetail.propTypes = {
    reloadTable: PropTypes.func.isRequired,
    iconTooltipId: PropTypes.string.isRequired,
    entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    documentType: PropTypes.string.isRequired,
    disable: PropTypes.bool.isRequired,
  };
  const [document, setDocument] = useState();

  const handleDocumentChange = (e) => {
    const file = e.target.files[0];
    setDocument(file);
    handleSubmitData(file);
  };

  const handleSubmitData = async (file) => {
    let docData = new FormData();
    docData.append("documentType", documentType);
    docData.append("file", file);

    await AxiosInstance.post(`${documentDetailUpload}/${entityId}`, docData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        reloadTable();
      })
      .catch(() => {});
  };

  return (
    <Grid
      container
      justify="center"
      lg={1}
      sm={1}
      xl={1}
      xs={1}
      m={1}>
      <Form className="employeeForm">
        <Input
          id="file"
          name="documentPath"
          type="file"
          style={{ display: "none" }}
          onChange={handleDocumentChange}
        />
        <Tooltip title={<IntlMessages id={iconTooltipId} />}>
          <label
            htmlFor="file"
            className={disable ? "d-none" : ""}>
            <IconButton
              className="filter-button-padding"
              aria-label="upload picture"
              component="span">
              <AddIcon className="add-icon" />
            </IconButton>
          </label>
        </Tooltip>
      </Form>
    </Grid>
  );
};
export default AddDocumentDetail;
