import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { downloadCompanyDocumentApi } from "../../redux/ApiLists";
import { getActionsObject } from "../../util/Helper";
import { DOCUMENT_TYPES } from "../shared/Enums";
import DataTableWrapper from "../shared/dataTable/DataTableWrapper";
import ListTypes from "../shared/dataTable/ListTypes";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import CompactMenuWithActions from "../shared/spinner/CompactMenuWithActions";

const Policies = () => {
  const cubeModelName = "CompanyDocument";
  const [reload, reloadTable] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const defaultFilter = {
    dimension: `${cubeModelName}.visibleToEmployee`,
    operator: "equals",
    values: ["true"],
  };

  const defaultSortOrder = [`${cubeModelName}.documentName`, "asc"];

  const columns = [
    {
      text: "ID",
      value: `${cubeModelName}.companyDocumentId`,
      type: "number",
      isDisplay: false,
      isVisible: false,
      isSorting: false,
    },
    {
      text: "companyDocuments.cols.txt.documentName",
      value: `${cubeModelName}.documentName`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
      isSearchable: true,
    },
    {
      text: "companyDocuments.cols.txt.categoryName",
      value: `DocumentCategory.categoryName`,
      type: "string",
      isDisplay: true,
      isVisible: true,
      isSorting: true,
    },
    {
      text: "cols.txt.action",
      value: "action_column",
      isDisplay: true,
      isVisible: false,
      isCustomCol: true,
      isSorting: false,
    },
  ];
  const filterOptions = [];
  const API = {
    download: `${downloadCompanyDocumentApi}`,
  };
  const actionHtml = (rowData) => {
    const refreshTable = () =>
      setTimeout(() => {
        reloadTable((prev) => !prev);
      }, 1500);

    const options = getActionsObject(rowData, cubeModelName);
    return (
      <Row>
        <Col className="actionButtons">
          <CompactMenuWithActions
            options={options}
            rowData={rowData}
            reloadTable={refreshTable}
            cubeModelName={cubeModelName}
            showAttachments={true}
            ShowStatus={false}
            api={API}
            Id={rowData[`${cubeModelName}.companyDocumentId`]}
            message={"Document"}
            cubeModelId={rowData[`${cubeModelName}.companyDocumentId`]}
            documentType={DOCUMENT_TYPES.COMPANY_DOCUMENT}
            disable={true}
          />
        </Col>
      </Row>
    );
  };
  const customCols = [{ id: "action_column", component: actionHtml }];
  const customToolList = [];

  return (
    <>
      <TitleHeader pageTitle={"title.companyDocuments"} />
      <DataTableWrapper
        key={`key_employee_list_${reload}`}
        cubeModelName={cubeModelName}
        listType={ListTypes.documentList}
        defaultFilter={defaultFilter}
        columns={columns}
        toolsList={customToolList}
        defaultSortOrder={defaultSortOrder}
        customColumns={customCols}
        setSelectedRowData={setSelectedRowData}
        toolbarSize={12}
        filterOptions={filterOptions}
      />
    </>
  );
};

export default Policies;
