import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import ModelSelfFooter from "../../components/shared/Model/ModelSelfFooter";
import Colors from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import { documentCategoryApi, uploadDocumentApi } from "../../redux/ApiLists";
import { getAllEnumsAction } from "../../redux/actions/utilityControllerAction";
import IntlMessages from "../../util/IntlMessages";
import "./style.css";

const AddDocument = ({ openDialog, setOpenDialog, reloadTable }) => {
  AddDocument.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    reloadTable: PropTypes.func,
  };
  const [, setAllEnums] = useState([]);
  const [documentsCategory, setDocumentsCategory] = useState([]);

  let validationSchema = yup.object().shape({
    documentName: yup.string().trim().required(""),
  });

  const formik = useFormik({
    initialValues: {
      employeeDocumentId: "",
      documentName: "",
      documentCategoryId: "",
      documentCategory: "",
      visibleToEmployee: true,
      approved: false,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  const handleSubmit = async (values) => {
    await AxiosInstance.post(`${uploadDocumentApi}`, values)
      .then((response) => {
        setOpenDialog(false);
        reloadTable((prev) => !prev);
      })
      .catch(() => {
        setOpenDialog(true);
      });
  };

  const filterDocumentCategory = async () => {
    const result = await AxiosInstance.get(`${documentCategoryApi}`)
      .then((response) => {
        if (response.length <= 0) {
          setError(...response.message);
        } else {
          setDocumentsCategory(response);
          setError("");
        }
      })
      .catch((error) => {
        if (error.response) {
          setError(error?.response?.data.data?.message);
        } else if (error.request) {
          setError(error?.request);
        }
      });
  };

  useEffect(() => {
    filterDocumentCategory();
  }, []);

  const dispatch = useDispatch();
  // Get All E-Nums
  const AllEnumsStore = useSelector((store) => {
    return store?.utilityControllerReducer?.utilAllEnums;
  });
  useEffect(() => {
    if (!AllEnumsStore) {
      dispatch(getAllEnumsAction());
    }
    if (AllEnumsStore && Object.keys(AllEnumsStore).length > 0) {
      setAllEnums(AllEnumsStore?.documentFileType);
    }
  }, [AllEnumsStore]);

  if (formik.isSubmitting && formik.isValidating && !formik.isValid) {
    toast.error("Please fill all the mandatory fields", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <ModelSelfFooter
      open={openDialog}
      heading={<IntlMessages id="myDocuments.txt.title" />}
      closePopUp={
        <Tooltip
          title="Close"
          placement="top">
          <CloseIcon
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        </Tooltip>
      }
      closeFn={() => setOpenDialog(false)}
      maxWidth="md"
      footer={<span className="DisplayNone">.</span>}>
      <Form
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        className="mdForm"
        onSubmit={formik.handleSubmit}>
        <div className="formBlock">
          <Row>
            <Col
              xs="12"
              md="6">
              <FormGroup>
                <Label htmlFor="Reporting Manager">
                  <IntlMessages id="myDocuments.txt.documentCategory" />
                </Label>
                <Autocomplete
                  id="documentCategory"
                  className="documentCategory removeLabel"
                  name="documentCategory"
                  hiddenLabel="true"
                  options={documentsCategory.map((docCategory) => ({
                    docCategory: docCategory,
                    label: docCategory.categoryName,
                  }))}
                  value={formik?.values?.documentCategory}
                  onChange={(e, value) => {
                    formik.setFieldValue("documentCategoryId", value?.docCategory?.documentCategoryId);
                    formik.setFieldValue("documentCategory", value?.label);
                    formik.setFieldValue(value?.label);
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="documentCategory"
                      size="small"
                      variant="outlined"
                      autoFocus={true}
                      className="autoComplete-text-field"
                    />
                  )}
                />
              </FormGroup>
            </Col>
            <Col
              xs="12"
              md="6">
              <FormGroup>
                <Label
                  htmlFor="documentName"
                  className="requiredField">
                  <IntlMessages id="myDocuments.txt.documentName" />
                </Label>
                <Input
                  type="text"
                  id="documentName"
                  name="documentName"
                  value={formik.values.documentName}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.documentName && !formik.values.documentName}
                  onBlur={formik.handleBlur}
                  style={{ height: "33px", marginTop: "4px" }}
                />
                {renderErrorMessage("documentName")}
              </FormGroup>
            </Col>
          </Row>
        </div>
        <Row className="FooterForm">
          <Button
            variant="contained"
            color="outline-secondary"
            onClick={() => setOpenDialog(false)}
            className="buttonSpacing">
            <IntlMessages id={`button.text.cancel`} />
          </Button>
          <Button
            style={{ backgroundColor: Colors.Blue }}
            variant="contained"
            type="submit"
            className="buttonSpacing">
            <IntlMessages id={`button.text.save`} />
          </Button>
        </Row>
      </Form>
    </ModelSelfFooter>
  );
};

export default AddDocument;
