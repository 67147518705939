import React from "react";
import { useCubeQuery } from "@cubejs-client/react";
import { Card, CardActions, CardContent, Table, TableBody, TableCell, TableHead, TableRow, TablePagination, Typography, Checkbox, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import IntlMessages from "../../../util/IntlMessages";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import palette from "./palette";
import { dateFormatter, dateTimeFormatter, getNumberMonthYearDateToString } from "../../../util/Helper";
import LenthNshow from "../LengthNShow";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Spinner from "../spinner/Spinner";
import StatusColumnUI from "./StatusUI";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import { monthName } from "../Enums";
import { setRowsPerPageInStorage, setSortOrder } from "./PreferenceHelper";
import ProfilePic from "../../profilePic/ProfilePic";
import { downloadEmployeePhoto } from "../../../redux/ApiLists";
import PropTypes from "prop-types";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  content: {
    padding: 0,
  },
  head: {
    backgroundColor: palette.background.gray,
  },
  inner: {
    minWidth: 100,
  },
  nameContainer: {
    display: "flex",
    alignItems: "baseline",
  },
  status: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  tableRow: {
    padding: "0 5px",
    ".MuiTableRow-root.MuiTableRow-hover&:hover": {
      backgroundColor: palette.primary.action,
    },
  },
  columnText: {
    fontWeight: "bold",
  },
  hoverable: {
    "&:hover": {
      color: `${palette.primary.normal}`,
      cursor: `pointer`,
    },
  },
  clickableColumns: {
    color: `${palette.primary.normal}`,
  },
  arrow: {
    fontSize: 10,
    position: "absolute",
  },
}));

export const GenericTable = (props) => {
  const classes = useStyles();
  const {
    className,
    sorting,
    setSorting,
    query,
    countQuery,
    rowsPerPage,
    page,
    setRowsPerPage,
    setPage,
    columns,
    cubeModelName,
    customColumns,
    setSelectedRowData,
    selectedRows,
    setSelectedRows,
    totalRowCount,
    setTotalRowCount,
    listType,
    ...rest
  } = props;
  // Define prop types
  GenericTable.propTypes = {
    className: PropTypes.string,
    sorting: PropTypes.array,
    setSorting: PropTypes.func.isRequired,
    query: PropTypes.object,
    countQuery: PropTypes.object,
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    setRowsPerPage: PropTypes.func.isRequired,
    setPage: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    cubeModelName: PropTypes.string.isRequired,
    customColumns: PropTypes.array,
    setSelectedRowData: PropTypes.func,
    selectedRows: PropTypes.array,
    setSelectedRows: PropTypes.func,
    totalRowCount: PropTypes.number,
    setTotalRowCount: PropTypes.func,
    listType: PropTypes.string,
  };

  GenericTable.defaultProps = {
    className: "",
    query: "",
    countQuery: "",
    customColumns: [],
  };
  const load = useCubeQuery(query, { subscribe: false });
  const count = useCubeQuery(countQuery, { subscribe: false });
  if (load.isLoading || count.isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: `${124 + 1 * 54}px`,
          background: "#fff",
          borderRadius: "5px",
        }}>
        <Spinner />
        {/* <CircularProgress color="primary" /> */}
      </div>
    );
  }
  if (load.error || count.error) {
    return <pre>{load.error.toString()}</pre>;
  }
  if (!load.resultSet || !count.resultSet) {
    return null;
  }
  if (load.resultSet && count.resultSet) {
    let tablePivot = load.resultSet.tablePivot();
    const setPageInTable = () => {
      let tcount = parseInt(count.resultSet.tablePivot()[0][`${cubeModelName}.count`]);
      let tpage = page * rowsPerPage >= tcount ? 0 : page;
      handlePageChange(null, tpage);
      return tpage;
    };
    const handlePageChange = (event, page) => {
      setPage(page);
    };
    const handleRowsPerPageChange = (event) => {
      setRowsPerPageInStorage(listType, event.target.value);
      setRowsPerPage(event.target.value);
      let tcount = parseInt(count.resultSet.tablePivot()[0][`${cubeModelName}.count`]);
      let tpage = page * event.target.value >= tcount ? 0 : page;
      setPage(tpage);
    };
    const handleSetSorting = (str) => {
      let newSortingOrder = [str, sorting[1] === "desc" ? "asc" : "desc"];
      setSortOrder(listType, newSortingOrder);
      setSorting(newSortingOrder);
    };
    const handleCellClick = (item, rowValues) => {
      if (item.isClickable && rowValues[`${item.value}`]) {
        setSelectedRowData({ clickedColumn: item.value, rowData: rowValues });
      }
    };
    const handleTooltip = (value) => {
      return (
        <Tooltip title={value || " "}>
          <span
            className="nowrap"
            id={value}
            name="description">
            <LenthNshow value={value} />
          </span>
        </Tooltip>
      );
    };

    const URLS = {
      download: `${downloadEmployeePhoto}`,
    };

    return (
      <Card
        {...rest}
        padding={"0"}
        className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table
                size="small"
                aria-label="a dense table">
                <TableHead className={classes.head}>
                  <TableRow>
                    {columns.map(
                      (item) =>
                        item.isDisplay && (
                          <TableCell
                            style={item.style ? item.style : { minWidth: "15rem", maxWidth: "15rem" }}
                            key={item.value + Math.random()}
                            className={`${classes.columnText} ${item.isSorting ? classes.hoverable : null}`}
                            onClick={() => {
                              item.isSorting && handleSetSorting(`${item.value}`);
                            }}
                            padding="dense">
                            <span>
                              <IntlMessages id={item.text} />
                            </span>
                            <Typography
                              className={classes.arrow}
                              variant="body2"
                              component="span">
                              {sorting[0] === item.value ? sorting[1] === "desc" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> : null}
                            </Typography>
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tablePivot.map((obj) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={obj[`${cubeModelName}.${cubeModelName}_id`]}>
                      {columns.map(
                        (item, index) =>
                          item.isDisplay &&
                          (item.type === "checkbox" ? (
                            <TableCell
                              style={item.style ? item.style : { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                              className={classes.hoverable}
                              padding="dense"
                              onClick={() => handleCheckBoxClick(item, obj)}>
                              <Checkbox
                                color="primary"
                                key={index}
                                checked={!!selectedRows.find((row) => row[`${cubeModelName}.${cubeModelName}_id`] === obj[`${cubeModelName}.${cubeModelName}_id`])}></Checkbox>
                            </TableCell>
                          ) : (
                            <TableCell
                              style={item.style ? item.style : { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                              className={item.isClickable && obj[`${item.value}`] ? classes.clickableColumns : ""}
                              padding="dense"
                              onClick={() => handleCellClick(item, obj)}>
                              {item.isStatusColumn ? (
                                <StatusColumnUI
                                  status={obj[`${item.value}`]}
                                  statusTypeList={item.statusTypeList}
                                />
                              ) : (
                                <div className={item.isEmpPic ? "d-flex align-items-center gap-3 mobile-align-pic" : ""}>
                                  {item.isEmpPic ? (
                                    <div className="EmpImageAreaInList">
                                      <ProfilePic
                                        Id={obj[`${cubeModelName}.employeeId`]}
                                        withId={true}
                                        shouldEdit={false}
                                        urls={URLS}
                                        reload={true}
                                        forDefaultImg={true}
                                        clickable={false}
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {!item.isCustomCol &&
                                    item.type === "currency" &&
                                    obj[`${item.value}`]?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                    })}
                                  {!item.isCustomCol && item.isFlag && (
                                    <>
                                      {!!obj[`${item.value}`] ? (
                                        <span className="renderStatusIcon renderStatusIconGreen">
                                          <DoneSharpIcon />
                                        </span>
                                      ) : (
                                        <span className="renderStatusIcon renderStatusIconRed">
                                          <CloseIcon />
                                        </span>
                                      )}
                                    </>
                                  )}
                                  {!item.isCustomCol && item.type === "string" && (
                                    <>
                                      <span
                                        id={"object_id_" + obj[`${cubeModelName}.id`]}
                                        name="object_name">
                                        {obj[`${item.value}`]}
                                        &nbsp;
                                      </span>
                                      {item.defaultVal && (obj[`${item.value}`] ? "" : item.defaultVal)}
                                      {item.showAnchorIcon && obj[`${item.value}`] && (
                                        <Typography
                                          className={classes.arrow}
                                          variant="body2"
                                          component="span">
                                          <OpenInNewIcon fontSize="small" />
                                        </Typography>
                                      )}
                                    </>
                                  )}
                                  {!item.isCustomCol && item.type === "month" && monthName[obj[`${item.value}`] - 1]}
                                  {!item.isCustomCol && item.type === "date" && (obj[`${item.value}`] ? dateFormatter(obj[`${item.value}`]) : "")}
                                  {!item.isCustomCol && item.type === "year_month" && (obj[`${item.value}`] ? yearMonthFormatter(obj[`${item.value}`]) : "")}
                                  {!item.isCustomCol && item.type === "datetime" && (obj[`${item.value}`] ? dateTimeFormatter(obj[`${item.value}`]) : "")}
                                  {!item.isCustomCol && item.type === "translate" && (obj[`${item.value}`] ? <IntlMessages id={obj[`${item.value}`]} /> : "")}
                                  {!item.isCustomCol && item.isOccupantEmail && handleEmailCol(obj)}
                                  {item.isCustomCol && customColumns.find((x) => x.id === item.value).component(obj)}
                                  {!item.isCustomCol && item.type === "month_year" && (obj[`${item.value}`] ? getNumberMonthYearToString(obj[`${item.value}`]) : "")}
                                  {!item.isCustomCol && item.type === "month_year_date" && (obj[`${item.value}`] ? getNumberMonthYearDateToString(obj[`${item.value}`]) : "")}
                                </div>
                              )}
                            </TableCell>
                          ))
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={parseInt(count.resultSet.tablePivot()[0][`${cubeModelName}.count`])}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={setPageInTable()}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </CardActions>
      </Card>
    );
  }
};
