import React from "react";
import Login from "../components/authentication/Login";
import AssetsList from "../components/companyAssets/AssetsList";
import Policies from "../components/companyPolicies/policies.js";
import Home from "../components/dashboard/Home";
import Documents from "../components/documents/document";
import EducationInfo from "../components/educationInfo/EducationInfo.js";
import InsuranceList from "../components/insuranceDetails/InsuranceList";
import LeaveGrantList from "../components/leaveGrant/LeaveGrantList";
import LeaveRecordList from "../components/leaveRecord/LeaveRecordList";
import MyProfile from "../components/myProfile/MyProfile";
import TeamList from "../components/myProfile/TeamList";
import PaySlipsRecordWrapper from "../components/payslips";
import ProjectList from "../components/project/ProjectList";

const NoF = () => {
  return <p> No component found</p>;
};

const RoutesList = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Home,
    exact: true,
  },
  {
    path: "/my-profile",
    component: MyProfile,
    exact: true,
  },
  {
    path: "/leaves",
    component: LeaveRecordList,
    exact: true,
  },
  {
    path: "/leave-grant",
    component: LeaveGrantList,
    exact: true,
  },
  {
    path: "/educationInfo",
    component: EducationInfo,
    exact: true,
  },
  {
    path: "/assets",
    component: AssetsList,
  },
  {
    path: "/projects",
    component: ProjectList,
    exact: true,
  },
  {
    path: "/my-documents",
    component: Documents,
    exact: true,
  },
  {
    path: "/company-documents",
    component: Policies,
    exact: true,
  },
  {
    path: "/insurance",
    component: InsuranceList,
    exact: true,
  },
  {
    path: "/payslips",
    component: PaySlipsRecordWrapper,
    exact: true,
  },
  {
    path: "/team-list",
    component: TeamList,
    exact: true,
  },
];

export default RoutesList;
