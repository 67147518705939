import { Tooltip } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { AxiosInstance } from "../../config/AxiosConfig";
import { leaveGrantApi } from "../../redux/ApiLists";
import { getAllEnumsAction } from "../../redux/actions";
import { removeTimeFromDate } from "../../util/Helper";
import IntlMessages from "../../util/IntlMessages";
import { GrantStatusEnum } from "../shared/Enums";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import { FormFooter } from "../shared/fromFooter/FromFooter";

const ApplyLeaveGrant = ({ openDialog, setOpenDialog, reloadTable, rowData }) => {
  ApplyLeaveGrant.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
    reloadTable: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
  };
  const profile = JSON.parse(sessionStorage.getItem("profile"));
  const leaveCompOffSchema = "LeaveGrant";
  const leaveGrantId = rowData ? rowData[`${leaveCompOffSchema}.leaveGrantId`] : undefined;
  const employeeId = profile.attributes.employee_id;
  const [loading, setLoading] = useState(false);
  const [allEnums, setAllEnums] = useState({});
  const [hideShowDate, setHideShowDate] = useState(false);

  const validValue = (key) => {
    return rowData?.[key] ? rowData[key] : "";
  };
  let validationSchema = yup.object().shape({
    grantReason: yup.string().trim().required(""),
    leaveGrantType: yup.string().trim().required(""),
    // grantStatus: yup.string().trim().required(""),
  });

  const formik = useFormik({
    initialValues: {
      employeeId: employeeId,
      comment: validValue(`${leaveCompOffSchema}.comment`),
      fromDate: validValue(`${leaveCompOffSchema}.fromDate`),
      grantReason: validValue(`${leaveCompOffSchema}.grantReason`),
      leaveGrantType: validValue(`${leaveCompOffSchema}.leaveGrantType`),
      toDate: validValue(`${leaveCompOffSchema}.toDate`),
      grantStatus: validValue(`${leaveCompOffSchema}.grantStatus`),
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const dispatch = useDispatch();
  const AllEnumsStore = useSelector((store) => {
    return store?.utilityControllerReducer?.utilAllEnums;
  });
  useEffect(() => {
    if (!AllEnumsStore) {
      dispatch(getAllEnumsAction());
    } else {
      if (Object.keys(AllEnumsStore).length > 0) {
        setAllEnums(AllEnumsStore);
      }
    }
  }, [AllEnumsStore]);

  if (formik.isSubmitting && formik.isValidating && !formik.isValid) {
    toast.error("Please fill all the mandatory fields", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  useEffect(() => {
    if (formik?.values?.leaveGrantType == "Maternity Leave" || formik?.values?.leaveGrantType === "Paternity Leave") {
      setHideShowDate(true);
    } else {
      setHideShowDate(false);
    }
  }, [formik?.values?.leaveGrantType]);

  const createLeaveGrant = async (values) => {
    setLoading(true);
    await AxiosInstance.post(`${leaveGrantApi}`, values)
      .then((response) => {
        setOpenDialog(false);
        reloadTable();
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  const updateLeaveGrant = async (values) => {
    setLoading(true);
    await AxiosInstance.put(`${leaveGrantApi}/${leaveGrantId}`, values)
      .then((response) => {
        setOpenDialog(false);
        reloadTable();
      })
      .catch(() => {
        setLoading(false);
      });
    setLoading(false);
  };
  const handleSubmit = async (values) => {
    if (leaveGrantId) {
      updateLeaveGrant(values);
    } else {
      createLeaveGrant(values);
    }
  };
  const grantStatusElement = (() => {
    if (rowData?.[`${leaveCompOffSchema}.grantStatus`]) {
      if (rowData[`${leaveCompOffSchema}.grantStatus`] === GrantStatusEnum.GRANT_APPROVED) {
        return (
          <span className="approvedStatus">
            <IntlMessages id="grantStatus.grantApproved.text" />
          </span>
        );
      } else if (rowData[`${leaveCompOffSchema}.grantStatus`] === GrantStatusEnum.GRANT_APPLIED) {
        return (
          <span className="appliedStatus">
            <IntlMessages id="grantStatus.grantApplied.text" />
          </span>
        );
      } else if (rowData[`${leaveCompOffSchema}.grantStatus`] === GrantStatusEnum.LEAVE_CANCELLED) {
        return (
          <span className="unapprovedStatus">
            <IntlMessages id="grantStatus.leaveCancelled.text" />
          </span>
        );
      } else {
        return (
          <span className="unapprovedStatus">
            <IntlMessages id="grantStatus.grantCancelled.text" />
          </span>
        );
      }
    }
  })();
  return (
    <ModelSelfFooter
      open={openDialog}
      heading={
        <>
          {" "}
          {employeeId ? <IntlMessages id="leave-grant-update" /> : <IntlMessages id="leave-grant-add" />}
          {<span style={{ marginLeft: "2%", fontSize: "16px" }}>{grantStatusElement}</span>}
        </>
      }
      closePopUp={
        <Tooltip
          title="Close"
          placement="top">
          <CloseIcon
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        </Tooltip>
      }
      closeFn={() => setOpenDialog(false)}
      maxWidth="md"
      footer={<span className="DisplayNone">.</span>}>
      <Form
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        onSubmit={formik.handleSubmit}
        className="mdForm">
        <div className="formBlock">
          <Row>
            <Col
              md={6}
              xs={12}>
              <FormGroup>
                <Label
                  htmlFor="leaveGrantType"
                  className="requiredField">
                  <IntlMessages id="leave-grant-leaveGrantType" />
                </Label>
                <Input
                  id="leaveGrantType"
                  name="leaveGrantType"
                  type="select"
                  onChange={formik.handleChange}
                  value={formik.values.leaveGrantType}
                  className="input-select-field-css"
                  invalid={formik.touched.leaveGrantType && !formik.values.leaveGrantType}
                  onBlur={formik.handleBlur}
                  autoFocus={true}>
                  <option value="">-- Select --</option>
                  {allEnums?.leaveGrantTypeEnumList &&
                    Object.values(allEnums.leaveGrantTypeEnumList).map((value) => (
                      <option
                        key={"leaveGrantType"}
                        value={value}>
                        {value}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
            <Col
              md={6}
              xs={12}>
              <FormGroup>
                <Label
                  htmlFor="grantReason"
                  className="requiredField">
                  <IntlMessages id="leave-grant-grantReason" />
                </Label>
                <Input
                  id="grantReason"
                  name="grantReason"
                  type="text"
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.grantReason && !formik.values.grantReason}
                  onBlur={formik.handleBlur}
                  value={formik.values.grantReason}
                />
              </FormGroup>
            </Col>
          </Row>
          {!hideShowDate && (
            <Row>
              <Col
                md={6}
                xs={12}>
                <FormGroup>
                  <Label htmlFor="fromDate">
                    <IntlMessages id="leave-grant-fromDate" />
                  </Label>
                  <Input
                    id="fromDate"
                    name="fromDate"
                    type="date"
                    onChange={formik.handleChange}
                    value={removeTimeFromDate(formik.values.fromDate)}
                  />
                </FormGroup>
              </Col>
              <Col
                md={6}
                xs={12}>
                <FormGroup>
                  <Label htmlFor="toDate">
                    <IntlMessages id="leave-grant-toDate" />
                  </Label>
                  <Input
                    id="toDate"
                    name="toDate"
                    type="date"
                    onChange={formik.handleChange}
                    value={removeTimeFromDate(formik.values.toDate)}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row>
            <Col
              md={12}
              xs={12}>
              <FormGroup>
                <Label htmlFor="comment">
                  <IntlMessages id="leave-grant-comment" />
                </Label>
                <Input
                  type="text"
                  id="comment"
                  name="comment"
                  onChange={formik.handleChange}
                  value={formik.values.comment}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <FormFooter
          loading={loading}
          setOpenDialog={setOpenDialog}
        />
      </Form>
    </ModelSelfFooter>
  );
};
export default ApplyLeaveGrant;
