import PropTypes from "prop-types";
import React from "react";
const FileEditButton = ({ setOpenDialog, disableButton }) => {
  FileEditButton.propTypes = {
    setOpenDialog: PropTypes.func.isRequired,
    disableButton: PropTypes.bool,
  };
  return (
    <>
      {disableButton == true ? (
        <div className="edit-action">
          <span style={{ marginLeft: "14px", color: "#acabab", fontSize: "0.85rem" }}>Edit</span>
        </div>
      ) : (
        <div
          className="edit-action"
          onClick={() => setOpenDialog(true)}>
          <span style={{ marginLeft: "14px", fontSize: "0.85rem" }}>Edit</span>
        </div>
      )}
    </>
  );
};

export default FileEditButton;
