import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    // marginTop: theme.spacing(0),
  },
  spacer: {
    flexGrow: 1,
  },
}));

const Toolbar = (props) => {
  const { className, toolList, setSearchKeyword, toolbarSize, ...rest } = props;

  const classes = useStyles();
  return (
    <div {...rest}>
      <Grid
        container
        justify="flex-end"
        spacing={1}
        className="mb-2">
        {toolList.map((tool) => tool)}
      </Grid>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
