import moment from "moment/moment";
import IntlMessages from "./IntlMessages";
import { GrantStatusEnum } from "../components/shared/Enums";

export const enumPunchType = { IN: "IN", OUT: "OUT" };

export const monthShortName = (dateValue) => {
  const date = new Date(dateValue);
  return date.toLocaleString("en-US", {
    month: "short",
  });
};

export const dayShortName = (dateValue) => {
  const d = new Date(dateValue);
  switch (d.getDay()) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
  }
};

export const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber);
  return monthShortName(date);
};

export const monthIn2Digit = (dateValue) => {
  const date = new Date(dateValue);
  switch (date.getMonth()) {
    case 0:
      return "01";
    case 1:
      return "02";
    case 2:
      return "03";
    case 3:
      return "04";
    case 4:
      return "05";
    case 5:
      return "06";
    case 6:
      return "07";
    case 7:
      return "08";
    case 8:
      return "09";
    case 9:
      return "10";
    case 10:
      return "11";
    case 11:
      return "12";
  }
};
export const formatDate = (dateValue) => {
  const d = new Date(dateValue);
  const date = ("0" + d.getDate()).slice(-2);
  const month = d.toLocaleString("en-US", { month: "short" });
  return `${date} ${month}`;
};

export const dayAndMonth = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  return ("0" + date).slice(-2) + " " + monthShortName(dateValue);
};

export const dateTimeFormatter = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  const month = monthShortName(d);
  const year = d.getFullYear();
  return date + " " + month + " " + year + " " + d.toString().substr(16, 8);
};

export const dateFormatter = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  const month = monthShortName(d);
  const year = d.getFullYear();
  return date + " " + month + " " + year;
};

export const dateEventFormatter = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  const month = monthShortName(d);
  const year = d.getFullYear();
  return ("0" + date).slice(-2) + " " + month + " " + year;
};

export const currencyConverter = (amount) => {
  return amount.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
};

// export const removeTimeFromDate = (dateWithTime) => {
//   if (dateWithTime == null) {
//     return null;
//   }
//   let strArr = dateWithTime?.split("T");
//   return strArr;
// };
export const removeTimeFromDate = (dateWithTime) => {
  if (dateWithTime == null) {
    return;
  }
  let strArr = dateWithTime.split("T");
  return strArr[0];
};

export const onlyCurrencyConverter = (amount) => {
  const newAmount = amount.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
  return newAmount.substring(1, newAmount.length);
};
export const getShowViewOption = (rowData, cubeModelName) => {
  let disableDocView = true;
  let arr = rowData[`${cubeModelName}.documentPath`].split(".");
  let fileType = arr[arr.length - 1];
  if (fileType == "doc" || fileType == "docs" || fileType == "xls" || fileType == "xlsx" || fileType == "txt") {
    disableDocView = false;
  }
  return disableDocView;
};

export const getActionsObject = (rowData, cubeModelName, handleActionCall) => {
  //Active,Inactive,Released,Resigned,Retired,Terminated,Approved,Cancelled,Paid,Completed,On-Hold,Executed,Redeemed
  const options = [];
  let actions = rowData[`${cubeModelName}.actions`];
  let grantStatus = rowData[`${cubeModelName}.grantStatus`];
  if (actions == null || actions == undefined) {
    return options;
  }
  actions = actions.split(",");
  if (actions) {
    actions?.map((item) => {
      if (item == "Active") {
        options.push({
          label: <IntlMessages id="active.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Redeemed") {
        options.push({
          label: <IntlMessages id="redeemed.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Executed") {
        options.push({
          label: <IntlMessages id="executed.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Skipped") {
        options.push({
          label: <IntlMessages id="skipped.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Error") {
        options.push({
          label: <IntlMessages id="error.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Completed") {
        options.push({
          label: <IntlMessages id="completed.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "On-Hold") {
        options.push({
          label: <IntlMessages id="on-hold.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Inactive") {
        options.push({
          label: <IntlMessages id="inactive.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Released") {
        options.push({
          label: <IntlMessages id="released.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Resigned") {
        options.push({
          label: <IntlMessages id="resigned.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Retired") {
        options.push({
          label: <IntlMessages id="retired.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Terminated") {
        options.push({
          label: <IntlMessages id="terminated.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Approved") {
        options.push({
          label: <IntlMessages id="approved.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Cancelled") {
        options.push({
          label: <IntlMessages id="cancelled.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Paid") {
        options.push({
          label: <IntlMessages id="paid.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Created") {
        options.push({
          label: <IntlMessages id="created.action.text" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
      if (item == "Grant Applied") {
        options.push({
          label: (
            <div style={{ fontFamily: "poppins", fontSize: "0.85rem", marginLeft: "14px" }}>
              <IntlMessages id="leave-grant-applied" />
            </div>
          ),
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }

      if (grantStatus != GrantStatusEnum.GRANT_APPROVED && grantStatus != GrantStatusEnum.LEAVE_CANCELLED) {
        if (item == "Grant Cancelled") {
          options.push({
            label: (
              <div style={{ fontSize: "0.85rem", marginLeft: "14px", fontFamily: "Poppins" }}>
                <IntlMessages id="leave-grant-cancelled" />
              </div>
            ),
            onClick: handleActionCall,
            action: item,
            rowData: rowData,
          });
        }
      }
      if (item == "Leave Applied") {
        options.push({
          label: (
            <div style={{ marginLeft: "14px", fontSize: "0.85rem", fontFamily: "poppins" }}>
              <IntlMessages id="leave-applied" />
            </div>
          ),
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }

      if (grantStatus != GrantStatusEnum.LEAVE_APPROVED) {
        if (item == "Leave Cancelled") {
          options.push({
            label: (
              <div style={{ marginLeft: "14px", fontSize: "0.85rem", fontFamily: "poppins" }}>
                <IntlMessages id="leave-cancelled" />
              </div>
            ),
            onClick: handleActionCall,
            action: item,
            rowData: rowData,
          });
        }
      }
      if (item == "Disbursed") {
        options.push({
          label: <IntlMessages id="loan-disbursed" />,
          onClick: handleActionCall,
          action: item,
          rowData: rowData,
        });
      }
    });
  }
  return options;
};

export function calcDistance(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var lat1 = toRad(lat1);
  var lat2 = toRad(lat2);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d * 1000;
}

export function toRad(Value) {
  return (Value * Math.PI) / 180;
}

export function getCurrTime() {
  const dateTodayFormated = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
  return dateTodayFormated;
}

export function toCreateObject(currStatus) {
  return { date: getCurrTime(), punchType: currStatus };
}

export const getNumberMonthYearDateToString = (date) => {
  const d = new Date(date);
  const monthYear = d.getFullYear() + " - " + toMonthName(d.getMonth());
  return monthYear;
};
