/**
 * App Language Provider
 * Add more locales here
 */
import { addLocaleData } from "react-intl";
import enLang from "./entries/en-US";
import jaLang from "./entries/ja_JA";

const AppLocale = {
  en: enLang,
  ja: jaLang,
};

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.ja.data);

export default AppLocale;
