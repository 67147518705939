import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ClearPreference from "../../clearPreference/ClearPreference";
import "./appSettingMenu.css";

const AppSettingMenu = ({ anchorE2, openMenu, setAnchorE2 }) => {
  AppSettingMenu.propTypes = {
    anchorE2: PropTypes.any,
    openMenu: PropTypes.any,
    setAnchorE2: PropTypes.any,
  };

  const [openClearPreferencePopup, setOpenClearPreferencePopup] = useState(false);
  const handleCloseMenu = () => {
    setAnchorE2(null);
  };

  return (
    <>
      <Menu
        id="demo-positioned-menu"
        className="appSettingMenu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorE2}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
          right: "100px",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
          right: "100px",
        }}>
        <Link
          className="modify-link"
          to="#"
          onClick={() => setOpenClearPreferencePopup(true)}>
          <MenuItem onClick={handleCloseMenu}>Clear Local Preferences</MenuItem>
        </Link>
      </Menu>
      {openClearPreferencePopup && (
        <ClearPreference
          openDialog={openClearPreferencePopup}
          setOpenDialog={setOpenClearPreferencePopup}
        />
      )}
    </>
  );
};

export default AppSettingMenu;
